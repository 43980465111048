import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import TreasureUpdateForm from 'containers/app/treasures/TreasureUpdateForm';

type Props = {
  open: boolean;
  onClose: () => void;
  huntId: string;
  treasureId: null | string;
};

const TreasureUpdateModal: FC<Props> = ({ open, onClose, huntId, treasureId }) => {
  const { t } = useTranslation(['app']);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      title={t('app:treasures.editTreasure')}
      visible={open}
      onCancel={onClose}
      closable={!isLoading}
      maskClosable={!isLoading}
      getContainer={false}
      destroyOnClose={true}
      footer={false}
    >
      {treasureId && (
        <TreasureUpdateForm
          treasureId={treasureId}
          huntId={huntId}
          onClose={onClose}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </Modal>
  );
};

export default TreasureUpdateModal;
