export const AuthTestIds = {
  loginButton: 'login-button',
  emailInput: 'email-input',
  passwordInput: 'password-input',
};

export const HuntTestIds = {
  createButton: 'hunt-create-button',
  titleInput: 'hunt-title-input',
  descriptionInput: 'hunt-description-input',
  submitButton: 'hunt-submit-button',
  cancelButton: 'hunt-cancel-button',
  actionsButton: 'hunt-actions-button',
  detailsActionsButton: 'hunt-details-actions-button',
};

export const TreasureTestIds = {
  createButton: 'treasure-create-button',
  titleInput: 'treasure-title-input',
  descriptionInput: 'treasure-description-input',
  submitButton: 'treasure-submit-button',
  cancelButton: 'treasure-cancel-button',
  actionsButton: 'treasure-actions-button',
  detailsActionsButton: 'treasure-details-actions-button',
};

export const ProfileTestIds = {
  passwordInput: 'profile-password-input',
};
