import { useState, useCallback, useEffect, useMemo, lazy, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Breadcrumb, Spin, Tooltip } from 'antd';
import { LoadingOutlined, AppstoreFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useGetAllTreasuresQuery } from 'services/treasures';
import { useTreasuresLeftQuery, selectTier } from 'services/profile';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateSettings } from 'store/settings/settings.thunk';
import { selectTreasuresView } from 'store/settings/settings.slice';
import { useGetHuntQuery } from 'services/hunts';
import { AppHead, AppLayout, UpgradePlanModal } from 'shared/components';
import Tier from 'shared/constants/tier';
import TreasuresView from 'shared/constants/treasures-view';
import { TreasureTestIds } from 'shared/constants/test-ids';
import { appRoutes } from 'shared/routes/app-routes';
import { TreasureIllustration } from 'shared/resources/images';
import TreasureCreateModal from 'containers/app/treasures/TreasureCreateModal';
import TreasureUpdateModal from 'containers/app/treasures/TreasureUpdateModal';
import TreasuresCardView from './components/TreasuresCardView';
import HuntActionsMenu from './components/HuntActionsMenu';
import styles from './index.module.sass';

const TreasuresMapView = lazy(() => import('./components/TreasuresMapView'));

const TREASURES_PER_PAGE = 15;

type Props = {
  page: number;
  huntId: string;
};

const TreasuresContainer: FC<Props> = ({ page = 1, huntId }) => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedTreasuresView = useAppSelector(selectTreasuresView);
  const tier = useAppSelector((state) => selectTier(state));
  const { data: treasuresLeft } = useTreasuresLeftQuery('');
  const {
    data: allTreasures,
    isLoading: isTreasuresLoading,
    isFetching: isTreasuresFetching,
  } = useGetAllTreasuresQuery({
    page: page ?? 1,
    huntId,
    pagination: selectedTreasuresView === TreasuresView.CARD,
  });
  const { data: huntDetails, isSuccess: isGetHuntSuccess } = useGetHuntQuery(huntId, {
    skip: !!!huntId,
  });

  const [showCreateTreasureModal, setShowCreateTreasureModal] = useState(false);
  const [showUpdateTreasureModal, setShowUpdateTreasureModal] = useState(false);
  const [treasureId, setTreasureId] = useState<null | string>(null);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const selectTreasuresCardView = useCallback(() => {
    dispatch(updateSettings({ treasuresView: TreasuresView.CARD }));
  }, [dispatch]);

  const selectTreasuresMapView = useCallback(() => {
    dispatch(updateSettings({ treasuresView: TreasuresView.MAP }));
  }, [dispatch]);

  const openCreateTreasureModal = useCallback(() => {
    if (treasuresLeft == null || tier == null) {
      return;
    }

    if (tier === Tier.HUNTER || tier === Tier.HOARDER || treasuresLeft > 0) {
      setShowCreateTreasureModal(true);
      return;
    }

    setShowUpgradePlanModal(true);
  }, [treasuresLeft, tier]);

  const closeCreateTreasureModal = useCallback(() => {
    setTreasureId(null);
    setShowCreateTreasureModal(false);
  }, []);

  const openUpdateTreasureModal = useCallback((editTreasureId: string) => {
    setTreasureId(editTreasureId);
    setShowUpdateTreasureModal(true);
  }, []);

  const closeUpdateTreasureModal = useCallback(() => {
    setTreasureId(null);
    setShowUpdateTreasureModal(false);
  }, []);

  const treasuresView = useMemo(() => {
    if (!!allTreasures && allTreasures.treasures.length > 0) {
      return selectedTreasuresView;
    }

    return TreasuresView.CARD;
  }, [selectedTreasuresView, allTreasures]);

  const treasuresData = useMemo(() => {
    if (!!!allTreasures || isTreasuresFetching || isTreasuresLoading) {
      return null;
    }

    return {
      treasures: allTreasures.treasures,
      totalPages: Math.ceil(allTreasures.totalCount / TREASURES_PER_PAGE),
    };
  }, [allTreasures, isTreasuresFetching, isTreasuresLoading]);

  const breadcrumbs = useMemo(
    () => (
      <div className={styles.breadcrumbs}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={appRoutes.hunts()}>{t('app:hunts.hunts')}</Link>
          </Breadcrumb.Item>
          {isGetHuntSuccess && !!huntDetails && (
            <Breadcrumb.Item>{huntDetails.title}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
    ),
    [isGetHuntSuccess, huntDetails, t]
  );

  const huntTitle = useMemo(
    () =>
      isGetHuntSuccess &&
      !!huntDetails && <h1 className={styles.huntTitle}>{huntDetails.title}</h1>,
    [isGetHuntSuccess, huntDetails]
  );

  const newTreasureButton = useMemo(
    () => (
      <>
        {treasuresLeft !== undefined && (
          <Button
            type="primary"
            onClick={openCreateTreasureModal}
            data-testid={TreasureTestIds.createButton}
          >
            {t('app:treasures.newTreasure')}
          </Button>
        )}
        {treasuresLeft === undefined && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        )}
      </>
    ),
    [t, treasuresLeft, openCreateTreasureModal]
  );

  const actions = useMemo(
    () => (
      <div className={styles.viewButtons}>
        <div className={styles.editButton}>
          <HuntActionsMenu huntId={huntId} />
        </div>

        {treasuresData && treasuresData.treasures.length > 0 && (
          <>
            <Tooltip title={t('app:treasures.cardViewTooltip')}>
              <Button
                className={treasuresView === TreasuresView.CARD ? styles.selected : ''}
                icon={<AppstoreFilled />}
                onClick={selectTreasuresCardView}
              />
            </Tooltip>

            <Tooltip title={t('app:treasures.mapViewTooltip')}>
              <Button
                className={treasuresView === TreasuresView.MAP ? styles.selected : ''}
                icon={<FontAwesomeIcon icon={faLocationDot} />}
                onClick={selectTreasuresMapView}
              />
            </Tooltip>
          </>
        )}
      </div>
    ),
    [huntId, selectTreasuresCardView, selectTreasuresMapView, t, treasuresData, treasuresView]
  );

  // Set treasures data
  useEffect(() => {
    if (!!!allTreasures) {
      return;
    }

    // If last treasure is deleted on last page, navigate to a lower page
    if (allTreasures.treasures.length === 0 && page > 1) {
      // setTreasures(null);
      navigate(appRoutes.treasuresPage(huntId, page - 1));
    }

    // setTreasures(allTreasures.treasures);
    // setTotalPages(Math.ceil(allTreasures.totalCount / TREASURES_PER_PAGE));
  }, [allTreasures, huntId, isTreasuresFetching, isTreasuresLoading, navigate, page]);

  return (
    <AppLayout>
      <AppHead title={huntDetails?.title ?? t('app:treasures.treasures')} />
      <div className={styles.wrapper}>
        <div
          className={`${styles.column1} ${treasuresView === TreasuresView.MAP ? '' : styles.hide}`}
        >
          {breadcrumbs}
          {huntTitle}
        </div>

        <div
          className={`${styles.column2} ${treasuresView === TreasuresView.MAP ? '' : styles.hide}`}
        >
          {newTreasureButton}
          {actions}
        </div>

        <div className={`${treasuresView === TreasuresView.CARD ? styles.cardContent : ''}`}>
          <div
            className={`${styles.row1} ${treasuresView === TreasuresView.MAP ? styles.hide : ''}`}
          >
            {breadcrumbs}
            {newTreasureButton}
          </div>

          <div
            className={`${styles.row2} ${treasuresView === TreasuresView.MAP ? styles.hide : ''}`}
          >
            {huntTitle}
            {actions}
          </div>

          {(treasuresView === TreasuresView.CARD ||
            !!!treasuresData ||
            treasuresData.treasures.length === 0) && (
            <TreasuresCardView
              treasures={treasuresData?.treasures}
              huntId={huntId}
              page={page}
              openEditModal={openUpdateTreasureModal}
              totalPages={treasuresData?.totalPages ?? 1}
              loading={isTreasuresLoading}
            />
          )}

          {treasuresData && treasuresData.treasures.length === 0 && (
            <div className={styles.noTreasures}>
              <TreasureIllustration />
              <h2>{t('app:treasures.addNewTreasure')}</h2>
            </div>
          )}
        </div>

        <TreasuresMapView
          treasures={treasuresData?.treasures}
          open={
            treasuresView === TreasuresView.MAP &&
            !!treasuresData &&
            treasuresData.treasures.length > 0
          }
          loading={
            treasuresView === TreasuresView.MAP && (isTreasuresLoading || isTreasuresFetching)
          }
        />

        <UpgradePlanModal
          open={showUpgradePlanModal}
          onClose={() => setShowUpgradePlanModal(false)}
          title={t('app:treasures.limitReached')}
          content={t('app:treasures.limitReachedContent')}
        />

        <TreasureCreateModal
          open={showCreateTreasureModal}
          onClose={closeCreateTreasureModal}
          huntId={huntId}
        />

        <TreasureUpdateModal
          open={showUpdateTreasureModal}
          onClose={closeUpdateTreasureModal}
          huntId={huntId}
          treasureId={treasureId}
        />
      </div>
    </AppLayout>
  );
};

export default TreasuresContainer;
