import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLookupQuery } from 'services/auth';
import { publicRoutes } from 'shared/routes/public-routes';
import { appRoutes } from 'shared/routes/app-routes';
import { Loading } from 'shared/components';

const Home = () => {
  const { isError, isSuccess, isLoading, isFetching } = useLookupQuery('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || isFetching) {
      return;
    }

    if (isSuccess) {
      navigate(appRoutes.hunts(), { replace: true });
    } else {
      navigate(publicRoutes.login(), { replace: true });
    }
  }, [navigate, isSuccess, isError, isLoading, isFetching]);

  return <Loading />;
};

export default Home;
