import { createSelector } from '@reduxjs/toolkit';
import { poucherApi } from 'services';
import { transformDefaultResponse } from 'services/types';
import { Profile } from 'models/profile';
import { ProfileDetailsResponseData } from './types';

const extendedApi = poucherApi.injectEndpoints({
  endpoints: (build) => ({
    profileDetails: build.query({
      transformResponse: transformDefaultResponse<ProfileDetailsResponseData>(),
      query: () => ({
        url: '/profile',
        credentials: 'include',
      }),
    }),

    treasuresLeft: build.query({
      providesTags: ['TreasuresLeft'],
      transformResponse: transformDefaultResponse<number>(),
      query: () => ({
        url: '/profile/treasures-left',
        credentials: 'include',
      }),
    }),

    changePassword: build.mutation({
      query: (params: Profile.ChangePasswordFormValues) => ({
        url: '/profile/change-password',
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
    }),

    deleteAccount: build.mutation({
      invalidatesTags: ['Auth'],
      query: (params: Profile.DeleteAccountFormValues) => ({
        url: '/profile',
        method: 'DELETE',
        body: params,
        credentials: 'include',
      }),
    }),
  }),

  overrideExisting: false,
});

const selectProfileDetailsResult = extendedApi.endpoints.profileDetails.select({});

export const selectDetails = createSelector(selectProfileDetailsResult, (profileDetailsResult) => ({
  profileDetails: profileDetailsResult?.data,
  isLoading: profileDetailsResult?.isLoading,
  isSuccess: profileDetailsResult?.isSuccess,
}));

export const selectTier = createSelector(selectProfileDetailsResult, (profileDetailsResult) => {
  if (!profileDetailsResult || !profileDetailsResult.data) {
    return undefined;
  }

  return profileDetailsResult.data.tier;
});

export const {
  useProfileDetailsQuery,
  useTreasuresLeftQuery,
  useChangePasswordMutation,
  useDeleteAccountMutation,
} = extendedApi;
