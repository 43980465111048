import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import HuntCreateForm from 'containers/app/hunts/HuntCreateForm';

type Props = {
  open: boolean;
  onClose: () => void;
};

const HuntCreateModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(['app']);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      title={t('app:hunts.newHunt')}
      visible={open}
      onCancel={onClose}
      closable={!isLoading}
      maskClosable={!isLoading}
      getContainer={false}
      destroyOnClose={true}
      footer={false}
    >
      <HuntCreateForm onClose={onClose} isLoading={isLoading} setIsLoading={setIsLoading} />
    </Modal>
  );
};

export default HuntCreateModal;
