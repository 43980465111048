import { useCallback, ReactNode, FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Button, Modal } from 'antd';
import { OutlinedButton } from 'shared/components';
import Tier from 'shared/constants/tier';
import PaymentType from 'shared/constants/payment-type';
import styles from './index.module.sass';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const STRIPE_PORTAL_URL = `${API_BASE_URL}/payment/stripe/portal`;

type Props = {
  title: string;
  content: string;
  cardTier: Tier;
  currentTier: Tier;
  isCurrentPlan: boolean;
  priceValue: string;
  rawPriceValue: string;
  priceId: string;
  isBestOffer: boolean;
  loading: boolean;
  onSelect: (priceId: string) => void;
  icon: ReactNode;
  paymentType?: PaymentType;
};

const PriceCard: FC<Props> = ({
  title,
  content,
  cardTier,
  priceValue,
  rawPriceValue,
  priceId,
  currentTier,
  isCurrentPlan,
  isBestOffer,
  loading,
  onSelect,
  icon,
  paymentType,
}) => {
  const { t } = useTranslation(['translation', 'app']);

  const showUpdateSubscriptionConfirm = useCallback(
    (isUpgrade: boolean, priceId: string, plan: string, newValue: string, oldPlan?: string) => {
      let modalTitle, modalContent;

      if (isUpgrade) {
        modalTitle = <strong>{t('app:upgrade.upgradePlan')}</strong>;
        modalContent = (
          <Trans
            i18nKey={
              paymentType === PaymentType.STRIPE
                ? 'app:upgrade.updateSubscriptionContent1'
                : 'app:upgrade.updateSubscriptionContent3'
            }
            values={{ plan: plan.toUpperCase(), newValue }}
          />
        );
      } else {
        modalTitle = <strong>{t('app:upgrade.changePlan')}</strong>;
        modalContent = (
          <Trans
            i18nKey={
              paymentType === PaymentType.STRIPE
                ? 'app:upgrade.updateSubscriptionContent2'
                : 'app:upgrade.updateSubscriptionContent4'
            }
            values={{ plan: plan.toUpperCase(), newValue }}
          />
        );
      }

      Modal.confirm({
        title: modalTitle,
        icon: <div />,
        content: modalContent,
        okText: t('common.confirm'),
        okType: 'primary',
        cancelText: t('common.cancel'),
        width: 450,
        onOk() {
          onSelect(priceId);
        },
      });
    },
    [t, onSelect, paymentType]
  );

  const showManageConfirm = useCallback(() => {
    Modal.info({
      title: <strong>{t('app:upgrade.manageSubscriptionTitle')}</strong>,
      content: t('app:upgrade.manageSubscriptionContent', {
        store: paymentType === PaymentType.APP_STORE ? 'iOS App Store' : 'Android Play Store',
      }),
      okText: t('common.ok'),
      width: 450,
      cancelButtonProps: { style: { display: 'none' } },
    });
  }, [t, paymentType]);

  return (
    <div
      className={`${styles.priceCard} ${
        (currentTier === Tier.FREE && isBestOffer) || isCurrentPlan ? styles.highlight : ''
      }`}
    >
      <div className={styles.highlightNote}>
        {currentTier === Tier.FREE && isBestOffer && <span>{t('app:upgrade.bestOffer')}</span>}
        {isCurrentPlan && <span>{t('app:upgrade.currentPlan')}</span>}
      </div>
      <Card bordered={false}>
        <div className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <h1>{title}</h1>
          <p>{content}</p>
          <h2>{priceValue}</h2>
          {currentTier === Tier.FREE && isBestOffer && (
            <Button type="primary" loading={loading} onClick={() => onSelect(priceId)}>
              {t('common.select')}
            </Button>
          )}

          {currentTier === Tier.FREE && !isBestOffer && (
            <OutlinedButton loading={loading} onClick={() => onSelect(priceId)}>
              {t('common.select')}
            </OutlinedButton>
          )}

          {isCurrentPlan && paymentType === PaymentType.STRIPE && (
            <OutlinedButton loading={loading}>
              <a href={STRIPE_PORTAL_URL}>{t('app:upgrade.manage')}</a>
            </OutlinedButton>
          )}

          {isCurrentPlan && paymentType !== PaymentType.STRIPE && (
            <OutlinedButton loading={loading} onClick={showManageConfirm}>
              {t('app:upgrade.manage')}
            </OutlinedButton>
          )}

          {(currentTier > cardTier || (currentTier === cardTier && !isCurrentPlan)) && (
            <OutlinedButton
              loading={loading}
              onClick={() => showUpdateSubscriptionConfirm(false, priceId, title, rawPriceValue)}
            >
              {t('app:upgrade.changePlan')}
            </OutlinedButton>
          )}

          {currentTier < cardTier && currentTier !== Tier.FREE && (
            <Button
              type="primary"
              loading={loading}
              onClick={() => showUpdateSubscriptionConfirm(true, priceId, title, rawPriceValue)}
            >
              {t('app:upgrade.upgradePlan')}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default PriceCard;
