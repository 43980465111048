import { useCallback, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'store/store';
import { poucherApi } from 'services';
import { useTreasuresLeftQuery, selectDetails } from 'services/profile';
import { AppHead, AppLayout, OutlinedButton, UpgradeButton } from 'shared/components';
import Tier from 'shared/constants/tier';
import { getLocalizedDateString } from 'shared/helpers/date';
import PaymentType from 'shared/constants/payment-type';
import { appRoutes } from 'shared/routes/app-routes';
import styles from './index.module.sass';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const STRIPE_PORTAL_URL = `${API_BASE_URL}/payment/stripe/portal`;

const ProfileContainer = () => {
  const { t } = useTranslation(['translation', 'app']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const { profileDetails, isLoading, isSuccess } = useAppSelector((state) => selectDetails(state));
  const pendingPayment = searchParams.get('pending') === 'true';
  const { data: treasuresLeft } = useTreasuresLeftQuery('');
  const [queryPaymentStatus, { data: paymentData }] =
    // @ts-ignore
    poucherApi.endpoints.paymentStatus.useLazyQuery('', { skip: !pendingPayment });

  const handleManageSubscriptionClick = useCallback(() => {
    setIsLoadingSubscription(true);
  }, []);

  const handleChangePasswordClick = useCallback(() => {
    navigate(appRoutes.changePassword());
  }, [navigate]);

  const showDeleteAccountConfirm = useCallback(() => {
    Modal.confirm({
      title: t('app:profile.deleteConfirmationTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('app:profile.deleteConfirmationContent'),
      okText: t('common.delete'),
      okType: 'danger',
      cancelText: t('common.cancel'),
      wrapClassName: styles.modalWrapper,
      width: 450,
      onOk() {
        navigate(appRoutes.deleteAccount());
      },
    });
  }, [t, navigate]);

  // If payment is pending request the payment status every 10 seconds
  useEffect(() => {
    if (!pendingPayment) {
      return;
    }

    queryPaymentStatus();

    const interval = setInterval(() => {
      queryPaymentStatus();
    }, 5000);

    return () => clearInterval(interval);
  }, [pendingPayment, queryPaymentStatus]);

  useEffect(() => {
    if (!!paymentData && paymentData.status !== 4) {
      navigate(appRoutes.profile());
    }
  }, [paymentData, navigate]);

  return (
    <AppLayout>
      <AppHead title={t('app:profile.profile')} />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{t('app:profile.profile')}</h1>
        </div>

        {isLoading ? <Spin /> : null}

        {isSuccess && !!profileDetails && (
          <main>
            <section>
              <h2>{t('app:profile.details')}</h2>
              <div>
                <strong>{t('app:profile.email')}</strong> <span>{profileDetails.email}</span>
              </div>

              <div className={styles.buttons}>
                <OutlinedButton onClick={handleChangePasswordClick}>
                  {t('app:profile.changePassword')}
                </OutlinedButton>
              </div>
            </section>

            {pendingPayment && (
              <section>
                <h2>{t('app:profile.subscription')}</h2>
                <div className={styles.paymentPending}>
                  <Spin size="small" />
                  <span>{t('app:profile.waitingForPayment')}</span>
                </div>
              </section>
            )}

            {!pendingPayment && treasuresLeft && (
              <section>
                <h2>
                  {t('app:profile.subscription')}{' '}
                  {t(`app:upgrade.${profileDetails.tierName.toLowerCase()}`)}
                </h2>
                {profileDetails.tier < Tier.HUNTER && (
                  <>
                    <div>
                      <strong>{t('app:profile.treasuresThisMonth')}</strong>{' '}
                      <span>{`${profileDetails.treasuresPerMonth - treasuresLeft}/${
                        profileDetails.treasuresPerMonth
                      }`}</span>
                    </div>
                    {treasuresLeft <= 0 && (
                      <div>
                        <strong>{t('app:profile.moreTreasuresOn')}</strong>{' '}
                        <span>{getLocalizedDateString(profileDetails.treasureLimitResetDate)}</span>
                      </div>
                    )}
                  </>
                )}

                {profileDetails.tier >= Tier.HUNTER && (
                  <>
                    <div>
                      <strong>{t('app:profile.treasuresPerMonth')}</strong>{' '}
                      <span>{t('app:profile.unlimited')}</span>
                    </div>
                  </>
                )}

                <div className={styles.buttons}>
                  {profileDetails.tier === Tier.HOARDER && (
                    <OutlinedButton>
                      <Link to={appRoutes.upgrade()} className={styles.upgrade}>
                        {t('app:upgrade.changePlan')}
                      </Link>
                    </OutlinedButton>
                  )}

                  {profileDetails.tier !== Tier.HOARDER && (
                    <UpgradeButton
                      label={t('app:upgrade.upgradePlan')}
                      className={styles.upgrade}
                    />
                  )}

                  {profileDetails.paymentType === PaymentType.STRIPE && (
                    <OutlinedButton
                      onClick={handleManageSubscriptionClick}
                      loading={isLoadingSubscription}
                    >
                      <a href={STRIPE_PORTAL_URL}>{t('app:profile.manageSubscription')}</a>
                    </OutlinedButton>
                  )}
                </div>
              </section>
            )}

            <section>
              <h2>{t('app:profile.privacy')}</h2>

              <div className={styles.buttons}>
                <OutlinedButton danger onClick={showDeleteAccountConfirm}>
                  {t('app:profile.deleteAccount')}
                </OutlinedButton>
              </div>
            </section>
          </main>
        )}
      </div>
    </AppLayout>
  );
};

export default ProfileContainer;
