import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title?: string;
  overrideTitle?: string;
};

const AppHead: FC<Props> = ({ title, overrideTitle }) => {
  return (
    <Helmet>
      {/* General */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{overrideTitle ?? title ? `${title} • Poucher` : 'Poucher'}</title>
      <meta name="title" content="Poucher" />
      <meta name="description" content="" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;700&display=swap"
        rel="stylesheet"
      />

      {/* Social Media */}
      <meta property="og:title" content="Poucher" />
      <meta property="og:description" content="" />
      <meta property="og:image" content="https://app.getpoucher.com/assets/og-image.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="app" />
      <meta name="twitter:site" content="@getpoucher" />
      <meta name="twitter:app:name:iphone" content="" />
      <meta name="twitter:app:id:iphone" content="" />
      <meta name="twitter:app:id:ipad" content="" />
      <meta name="twitter:app:id:googleplay" content="" />

      {/* Favicons */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="apple-mobile-web-app-title" content="Poucher" />
      <meta name="application-name" content="Poucher" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default AppHead;
