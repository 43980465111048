import { useState, FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Spiral as Hamburger } from 'hamburger-react';
import styles from './index.module.sass';

interface Props {
  items: ItemType[];
}

const HamburgerMenu: FC<Props> = ({ items }) => {
  const { t } = useTranslation(['home']);
  const location = useLocation();
  const navigate = useNavigate();
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleToggle = useCallback((toggled: boolean) => {
    if (toggled) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, []);

  const handleMenuItemClick = useCallback(
    ({ key }: { key: string }) => {
      navigate(key);
      setIsHamburgerMenuOpen(false);
      document.body.style.overflow = 'unset';
    },
    [navigate]
  );

  return (
    <>
      <div
        className={`${styles.hamburgerMenu} ${
          isHamburgerMenuOpen ? styles.hamburgerMenuOpen : styles.hamburgerMenuClosed
        }`}
      >
        <Hamburger
          label={t('home:showMenu')}
          toggled={isHamburgerMenuOpen}
          toggle={setIsHamburgerMenuOpen}
          onToggle={handleToggle}
        />
      </div>
      <nav
        className={`${styles.slideInMenu} ${
          isHamburgerMenuOpen ? styles.slideInMenuOpen : styles.slideInMenuClosed
        }`}
      >
        <div className={styles.menuWrapper}>
          <Menu
            theme="dark"
            defaultSelectedKeys={[location.pathname]}
            onClick={handleMenuItemClick}
            items={items}
          ></Menu>
        </div>
      </nav>
    </>
  );
};

export default HamburgerMenu;
