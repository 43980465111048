import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { AppHead, AppLayout } from 'shared/components';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateSettings } from 'store/settings/settings.thunk';
import { selectLanguage } from 'store/settings/settings.slice';
import styles from './index.module.sass';

const SettingsContainer = () => {
  const { t } = useTranslation(['app']);
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const handleLanguageChange = useCallback(
    (value: string) => {
      dispatch(updateSettings({ language: value }));
    },
    [dispatch]
  );

  return (
    <AppLayout>
      <AppHead title={t('app:settings.settings')} />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{t('app:settings.settings')}</h1>
        </div>

        <Form wrapperCol={{ span: 12 }} layout="vertical">
          <Form.Item label={t('app:settings.language')} name="language">
            <div className={styles.field}>
              <Select defaultValue={language} onChange={handleLanguageChange}>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="es">Español</Select.Option>
              </Select>
            </div>
          </Form.Item>
        </Form>
      </div>
    </AppLayout>
  );
};

export default SettingsContainer;
