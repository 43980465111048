import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { AppstoreFilled, StarFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGear, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectSiderStatus } from 'store/settings/settings.slice';
import { selectDetails } from 'services/profile';
import { updateSettings } from 'store/settings/settings.thunk';
import { poucherApi } from 'services';
import authApi from 'services/auth';
import { publicRoutes } from 'shared/routes/public-routes';
import { appRoutes } from 'shared/routes/app-routes';
import { notification, NotificationType } from 'shared/helpers/notification';
import Tier from 'shared/constants/tier';
import { PoucherLogo } from 'shared/resources/images';
import styles from './index.module.sass';

const AppSider = () => {
  const { t } = useTranslation(['app', 'auth', 'error', 'help']);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const siderStatus = useAppSelector(selectSiderStatus);
  const { profileDetails } = useAppSelector((state) => selectDetails(state));
  const isCollapsed = siderStatus === 'collapsed';
  const [logout] = authApi.endpoints.logout.useLazyQuery();

  const handleLogout = useCallback(() => {
    logout('')
      .then(() => {
        dispatch(poucherApi.util.resetApiState());
        navigate(publicRoutes.login());
        notification(t('auth:logoutSuccessful'), NotificationType.SUCCESS);
      })
      .catch(() => {
        notification(t('error:unkown'), NotificationType.ERROR);
      });
  }, [dispatch, logout, navigate, t]);

  const handleMenuClick = useCallback(
    (props: any) => {
      if (props.key === 'logout') {
        handleLogout();
        return;
      }

      navigate(props.key);
    },
    [handleLogout, navigate]
  );

  const handleOnCollapse = useCallback(
    (collapsed: boolean) => {
      dispatch(updateSettings({ siderStatus: collapsed ? 'collapsed' : 'open' }));
    },
    [dispatch]
  );

  const selectedKeys = useMemo(() => {
    const pathname = location.pathname;

    if (pathname.includes('hunts') || pathname.includes('treasures')) {
      return [appRoutes.hunts()];
    }

    if (pathname.includes('profile')) {
      return ['profile', appRoutes.profile()];
    }

    if (pathname.includes('settings')) {
      return [appRoutes.settings()];
    }

    if (pathname.includes('help')) {
      return [appRoutes.help()];
    }

    return [];
  }, [location.pathname]);

  const items = useMemo(
    () => [
      ...(profileDetails?.tier === Tier.FREE
        ? [
            {
              label: t('app:upgrade.upgrade'),
              icon: (
                <div className={styles.upgradeIcon}>
                  <StarFilled />
                </div>
              ),
              key: appRoutes.upgrade(),
            },
          ]
        : []),
      {
        label: t('app:hunts.hunts'),
        icon: <AppstoreFilled />,
        key: appRoutes.hunts(),
      },
      {
        label: t('app:settings.settings'),
        icon: <FontAwesomeIcon icon={faGear} />,
        key: appRoutes.settings(),
      },
      {
        label: t('app:profile.account'),
        icon: <FontAwesomeIcon icon={faUser} />,
        key: 'profile',
        children: [
          {
            label: t('app:profile.profile'),
            key: appRoutes.profile(),
          },
          {
            label: t('auth:logout'),
            key: 'logout',
          },
        ],
      },
      {
        label: t('help:help'),
        icon: <FontAwesomeIcon icon={faCircleQuestion} />,
        key: appRoutes.help(),
      },
    ],
    [t, profileDetails?.tier]
  );

  if (siderStatus == null) {
    return <div />;
  }

  return (
    <Layout.Sider
      collapsible={true}
      defaultCollapsed={isCollapsed}
      collapsed={isCollapsed}
      onCollapse={handleOnCollapse}
      className={styles.sider}
    >
      <div className={styles.siderMenu}>
        <Link to={appRoutes.home()}>
          <div className={`${styles.logo} ${isCollapsed ? styles.collapsed : styles.expanded}`}>
            <PoucherLogo />
          </div>
        </Link>

        <Menu
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          selectedKeys={selectedKeys}
          items={items}
        />
      </div>
    </Layout.Sider>
  );
};

export default AppSider;
