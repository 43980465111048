import { FC, useCallback } from 'react';
import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Treasure } from 'models/treasure';
import { TreasureCard } from './components/TreasureCard';
import { appRoutes } from 'shared/routes/app-routes';
import styles from './index.module.sass';

type Props = {
  treasures?: null | Treasure.Entity[];
  huntId: string;
  page: number;
  totalPages: number;
  openEditModal: (id: string) => void;
  loading?: boolean;
};

const TreasuresCardView: FC<Props> = ({
  treasures,
  huntId,
  page,
  totalPages,
  openEditModal,
  loading = false,
}) => {
  const navigate = useNavigate();

  const handlePageChange = useCallback(
    (newPage: number) => {
      // Scroll to top and redirect when page is changed
      document.body.scrollTo(0, 0);
      navigate(appRoutes.treasuresPage(huntId, newPage));
    },
    [navigate, huntId]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.treasureCards}>
        {!loading &&
          !!treasures &&
          treasures.length > 0 &&
          treasures.map((treasure) => (
            <TreasureCard
              treasure={treasure}
              page={page}
              openEditModal={openEditModal}
              key={treasure.id}
            />
          ))}

        {loading && [
          <TreasureCard key={0} loading={true} />,
          <TreasureCard key={1} loading={true} />,
          <TreasureCard key={2} loading={true} />,
          <TreasureCard key={3} loading={true} />,
          <TreasureCard key={4} loading={true} />,
          <TreasureCard key={5} loading={true} />,
          <TreasureCard key={6} loading={true} />,
          <TreasureCard key={7} loading={true} />,
          <TreasureCard key={8} loading={true} />,
          <TreasureCard key={9} loading={true} />,
        ]}
      </div>

      {!!treasures && treasures.length > 0 && totalPages > 1 && (
        <div className={styles.pagination}>
          <Pagination
            current={page}
            defaultCurrent={page}
            total={totalPages}
            pageSize={1}
            onChange={handlePageChange}
            disabled={loading}
          />
        </div>
      )}
    </div>
  );
};

export default TreasuresCardView;
