import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'shared/routes/app-routes';
import { CrownOutlined } from '@ant-design/icons';
import styles from './index.module.sass';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
};

const UpgradePlanModal: FC<Props> = ({ open, onClose, title, content }) => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();

  const handleCancel = () => {
    onClose();
  };

  const handleUpgradeClick = () => {
    onClose();
    navigate(appRoutes.upgrade());
  };

  return (
    <Modal
      title={
        <div className={styles.title}>
          <CrownOutlined />
          <span>{title}</span>
        </div>
      }
      visible={open}
      onCancel={handleCancel}
      destroyOnClose={true}
      closable={true}
      footer={[
        <Button type="primary" onClick={handleUpgradeClick} key="upgrade">
          {t('app:upgradeNow')}
        </Button>,
        <Button onClick={handleCancel} key="later">
          {t('app:maybeLater')}
        </Button>,
      ]}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default UpgradePlanModal;
