import { useState, useCallback, useEffect, FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Upload, Button, message } from 'antd';
import { UploadOutlined, EditOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { useCreateHuntMutation } from 'services/hunts';
import { Hunt } from 'models/hunt';
import { getBase64 } from 'shared/helpers/image';
import { HuntTestIds } from 'shared/constants/test-ids';
import { ErrorContainer } from 'shared/components';
import styles from 'shared/styles/hunt-form.module.sass';

type Props = {
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const HuntCreateForm: FC<Props> = ({ onClose, isLoading, setIsLoading }) => {
  const { t } = useTranslation(['translation', 'app']);
  const [createHunt, { isLoading: isCreateHuntLoading, error: createHuntError }] =
    useCreateHuntMutation();
  const [form] = Form.useForm();
  const [coverPictureFile, setCoverPictureFile] = useState<null | File>(null);
  const [coverPictureUrl, setCoverPictureUrl] = useState<null | string>(null);

  const handleSubmit = useCallback(
    async (values: Hunt.CreateFormValues) => {
      // Compress picture file
      if (coverPictureFile) {
        try {
          // Select target max file size in MB
          const coverPictureFileSizeInMb = coverPictureFile.size / 1024 / 1024;
          const maxFileSize = Math.min(coverPictureFileSizeInMb / 2, 0.5);
          const compressedFile = await imageCompression(coverPictureFile, {
            maxSizeMB: maxFileSize,
            maxIteration: 100,
            useWebWorker: true,
          });
          values.coverPicture = compressedFile;
        } catch (err) {
          console.error(err);
        }
      }

      values.date = new Date(values.dateString).toISOString();

      createHunt(values)
        .unwrap()
        .then(() => {
          onClose();
        });
    },
    [coverPictureFile, createHunt, onClose]
  );

  const handleBeforeUpload = useCallback((file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload .jpg or .png pictures');
    }

    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      message.error('Picture must smaller than 4MB!');
    }

    if (!isLt4M || !isJpgOrPng) {
      return false;
    }

    getBase64(file, (url: string) => {
      setCoverPictureUrl(url);
    });

    setCoverPictureFile(file);
    return false;
  }, []);

  const handleOnRemove = useCallback(() => {
    setCoverPictureFile(null);
    setCoverPictureUrl(null);
  }, []);

  useEffect(() => {
    setIsLoading(isCreateHuntLoading);
    // eslint-disable-next-line
  }, [isCreateHuntLoading]);

  return (
    <Form
      id="hunt-create"
      form={form}
      size="large"
      name="createHunt"
      onFinish={handleSubmit}
      layout="vertical"
      preserve={false}
      className={styles.form}
    >
      <ErrorContainer error={createHuntError} className={styles.errorContainer} />
      <Form.Item
        name="coverImage"
        valuePropName="list"
        getValueFromEvent={() => {}} // Hack to fix bug of filename persisting after cancel or creation
      >
        <div className={styles.upload}>
          <Upload
            maxCount={1}
            beforeUpload={handleBeforeUpload}
            disabled={isLoading}
            onRemove={handleOnRemove}
          >
            {coverPictureUrl && (
              <div className={styles.coverPicture}>
                <div className={styles.coverCaption}>
                  <EditOutlined />
                  <span>{t('app:hunts.editCoverPicture')}</span>
                </div>
                <img src={coverPictureUrl} alt={t('app:hunts.cover')} />
              </div>
            )}
            {!coverPictureUrl && (
              <Button icon={<UploadOutlined />}>{t('app:hunts.addCoverPicture')}</Button>
            )}
          </Upload>
        </div>
      </Form.Item>

      <Form.Item
        name="title"
        rules={[
          { required: true, message: t('app:hunts.titleError') },
          { min: 2, message: t('app:hunts.titleValidationMinLength') },
          { max: 100, message: t('app:hunts.titleValidationMaxLength') },
        ]}
      >
        <Input
          placeholder={t('app:hunts.titlePlaceholder')}
          maxLength={100}
          disabled={isLoading}
          data-testid={HuntTestIds.titleInput}
        />
      </Form.Item>

      <Form.Item
        name="dateString"
        rules={[{ required: true, message: t('app:hunts.dateError') }]}
        initialValue={new Date().toISOString().split('T')[0]}
      >
        <Input disabled={isLoading} type="date" />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[
          { required: false, message: t('app:hunts.invalidDescription') },
          { max: 10000, message: t('app:hunts.descriptionValidationMaxLength') },
        ]}
      >
        <Input.TextArea
          placeholder={t('app:hunts.description')}
          maxLength={10000}
          disabled={isLoading}
          data-testid={HuntTestIds.descriptionInput}
        />
      </Form.Item>

      <div className={styles.buttons}>
        <Form.Item>
          <Button
            key="cancel"
            onClick={onClose}
            disabled={isLoading}
            size="middle"
            data-testid={HuntTestIds.cancelButton}
          >
            {t('common.cancel')}
          </Button>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              loading={isLoading}
              data-testid={HuntTestIds.submitButton}
              disabled={
                !form.isFieldsTouched(['title']) ||
                form.getFieldsError().some(({ errors }) => errors.length)
              }
            >
              {t('common.create')}
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default HuntCreateForm;
