import { useParams, useSearchParams } from 'react-router-dom';
import TreasuresContainer from 'containers/app/treasures/TreasuresContainer';

const HuntDetails = () => {
  const { huntId } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  if (!huntId) {
    return null;
  }

  return <TreasuresContainer page={parseInt(page)} huntId={huntId as string} />;
};

export default HuntDetails;
