import { AppHead, AppLayout } from 'shared/components';
import styles from './index.module.sass';

const SuccessContainer = () => {
  return (
    <AppLayout>
      <AppHead />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Success</h1>
        </div>
        <form action="http://localhost:3001/v1/payment/stripe/portal" method="GET">
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </div>
    </AppLayout>
  );
};

export default SuccessContainer;
