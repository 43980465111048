import { useMemo, FC, ReactNode } from 'react';
import { Button } from 'antd';
import styles from './index.module.sass';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  children?: ReactNode;
  loading?: boolean;
  danger?: boolean;
  icon?: ReactNode;
  className?: string;
  color?: 'brand' | 'blue';
};

const OutlinedButton: FC<Props> = ({
  loading,
  danger,
  onClick,
  children,
  icon,
  className,
  color = 'brand',
}) => {
  const colorClass = useMemo(() => {
    switch (color) {
      case 'blue':
        return styles.blue;
      default:
        return styles.brand;
    }
  }, [color]);

  return (
    <div className={`${styles.base} ${colorClass} ${className ?? ''}`}>
      <Button onClick={onClick} loading={loading} danger={danger} icon={icon}>
        {children}
      </Button>
    </div>
  );
};

export default OutlinedButton;
