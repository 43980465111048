import { FC, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { appRoutes } from 'shared/routes/app-routes';
import { HuntTestIds } from 'shared/constants/test-ids';
import { useDeleteHuntMutation } from 'services/hunts';
import { HuntsFilter } from 'models/hunt';
import HuntUpdateModal from 'containers/app/hunts/HuntUpdateModal';

type Props = {
  huntId: string;
};

const HuntActionsMenu: FC<Props> = ({ huntId }) => {
  const { t } = useTranslation(['translation', 'app']);
  const navigate = useNavigate();
  const [deleteHunt] = useDeleteHuntMutation();

  const [showUpdateHuntModal, setShowUpdateHuntModal] = useState(false);

  const openUpdateHuntModal = useCallback(() => {
    setShowUpdateHuntModal(true);
  }, []);

  const closeUpdateHuntModal = useCallback(() => {
    setShowUpdateHuntModal(false);
  }, []);

  const showDeleteConfirm = useCallback(() => {
    Modal.confirm({
      title: t('app:hunts.deleteConfirmationTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('app:hunts.deleteConfirmationContent'),
      okText: t('common.delete'),
      okType: 'danger',
      cancelText: t('common.cancel'),
      width: 450,
      onOk() {
        deleteHunt({
          huntId,
          page: 1,
          filter: HuntsFilter.RECENT,
        })
          .unwrap()
          .then(() => {
            navigate(appRoutes.hunts());
          });
      },
    });
  }, [deleteHunt, huntId, t, navigate]);

  const moreActionsDropdownMenu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: 'edit',
            label: t('app:hunts.editHunt'),
            onClick: openUpdateHuntModal,
          },
          {
            key: 'delete',
            danger: true,
            label: t('app:hunts.deleteHunt'),
            onClick: showDeleteConfirm,
          },
        ]}
      />
    ),
    [openUpdateHuntModal, showDeleteConfirm, t]
  );

  return (
    <>
      <Dropdown overlay={moreActionsDropdownMenu} placement="bottomRight" trigger={['click']}>
        <FontAwesomeIcon icon={faEllipsis} data-testid={HuntTestIds.detailsActionsButton} />
      </Dropdown>
      <HuntUpdateModal open={showUpdateHuntModal} onClose={closeUpdateHuntModal} huntId={huntId} />
    </>
  );
};

export default HuntActionsMenu;
