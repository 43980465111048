import { useCallback, useMemo, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteTreasureMutation } from 'services/treasures';
import { Dropdown, Menu, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { appRoutes } from 'shared/routes/app-routes';
import { TreasureTestIds } from 'shared/constants/test-ids';
import { Treasure } from 'models/treasure';
import TreasureUpdateModal from 'containers/app/treasures/TreasureUpdateModal';

type Props = {
  treasureDetails: Treasure.Details;
};

const TreasureActionsMenu: FC<Props> = ({ treasureDetails }) => {
  const { t } = useTranslation(['translation', 'app']);
  const navigate = useNavigate();

  const [showUpdateTreasureModal, setShowUpdateTreasureModal] = useState(false);
  const [deleteTreasure] = useDeleteTreasureMutation();

  const openUpdateTreasureModal = useCallback(() => {
    setShowUpdateTreasureModal(true);
  }, []);

  const closeUpdateTreasureModal = useCallback(() => {
    setShowUpdateTreasureModal(false);
  }, []);

  const showDeleteConfirm = useCallback(() => {
    Modal.confirm({
      title: t('app:treasures.deleteConfirmationTitle'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common.delete'),
      okType: 'danger',
      cancelText: t('common.cancel'),
      onOk: () => {
        deleteTreasure({
          treasureId: treasureDetails.id,
          huntId: treasureDetails.huntId,
          page: 1,
          pagination: true,
        })
          .unwrap()
          .then(() => {
            navigate(appRoutes.hunt(treasureDetails.huntId));
          });
      },
    });
  }, [treasureDetails, deleteTreasure, navigate, t]);

  const moreActionsDropdownMenu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: 'edit',
            label: t('app:treasures.editTreasure'),
            onClick: openUpdateTreasureModal,
          },
          {
            key: 'delete',
            danger: true,
            label: t('app:treasures.deleteTreasure'),
            onClick: showDeleteConfirm,
          },
        ]}
      />
    ),
    [showDeleteConfirm, openUpdateTreasureModal, t]
  );

  return (
    <>
      <Dropdown overlay={moreActionsDropdownMenu} placement="bottomRight" trigger={['click']}>
        <FontAwesomeIcon icon={faEllipsis} data-testid={TreasureTestIds.detailsActionsButton} />
      </Dropdown>
      <TreasureUpdateModal
        open={showUpdateTreasureModal}
        onClose={closeUpdateTreasureModal}
        treasureId={treasureDetails.id}
        huntId={treasureDetails.huntId}
      />
    </>
  );
};

export default TreasureActionsMenu;
