import { poucherApi } from 'services';
import { transformDefaultResponse } from 'services/types';
import { Hunt } from 'models/hunt';
import { GetAllHuntsArgs, GetAllHuntsResponseData, UpdateHuntArgs, DeleteHuntArgs } from './types';

const extendedApi = poucherApi.injectEndpoints({
  endpoints: (build) => ({
    getAllHunts: build.query({
      transformResponse: transformDefaultResponse<GetAllHuntsResponseData>(),
      providesTags: [{ type: 'Hunt', id: 'LIST' }],
      query: (args: GetAllHuntsArgs) => {
        const params = [];

        if (args.page) {
          params.push(`page=${args.page}`);
        }

        if (args.filter) {
          params.push(`filter=${args.filter}`);
        }

        // Get full params string
        let paramsString = '';

        if (params.length > 0) {
          paramsString = `?${params.reduce((p, c) => `${p}&${c}`)}`;
        }

        return {
          url: `/hunts${paramsString}`,
          credentials: 'include',
        };
      },
    }),

    getHunt: build.query({
      transformResponse: transformDefaultResponse<Hunt.Entity>(),
      providesTags: (_result, _error, arg) => [{ type: 'Hunt', id: arg as string }],
      query: (huntId?: null | string) => ({
        url: `/hunts/${huntId}`,
        credentials: 'include',
      }),
    }),

    createHunt: build.mutation({
      invalidatesTags: [{ type: 'Hunt', id: 'LIST' }],
      query: (params: Hunt.CreateFormValues) => {
        const formData = new FormData();
        // @ts-ignore
        Object.keys(params).forEach((key) => formData.append(key, params[key] || ''));

        return {
          url: '/hunts',
          method: 'POST',
          body: formData,
          credentials: 'include',
        };
      },
    }),

    updateHunt: build.mutation({
      invalidatesTags: (_result, _error, args) => [
        { type: 'Hunt', id: 'LIST' },
        { type: 'Hunt', id: args.huntId },
      ],
      query: (args: UpdateHuntArgs) => {
        const formData = new FormData();
        // @ts-ignore
        Object.keys(args.params).forEach((key) => formData.append(key, args.params[key] || ''));

        return {
          url: `/hunts/${args.huntId}`,
          method: 'PUT',
          body: formData,
          credentials: 'include',
        };
      },
    }),

    deleteHunt: build.mutation({
      invalidatesTags: [{ type: 'Hunt', id: 'LIST' }],
      query: (args: DeleteHuntArgs) => ({
        url: `/hunts/${args.huntId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      // onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     poucherApi.util.updateQueryData(
      //       // @ts-ignore
      //       'getAllHunts',
      //       { page: args.page, filter: args.filter },
      //       (draft) => {
      //         // @ts-ignore
      //         const huntIndex = draft.hunts.findIndex(
      //           (hunt: Hunt.Entity) => hunt.id === args.huntId
      //         );
      //         if (huntIndex !== -1) {
      //           // @ts-ignore
      //           draft.hunts.splice(huntIndex, 1);
      //         }
      //       }
      //     )
      //   );
      //   queryFulfilled.catch(patchResult.undo);
      // },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllHuntsQuery,
  useGetHuntQuery,
  useCreateHuntMutation,
  useUpdateHuntMutation,
  useDeleteHuntMutation,
} = extendedApi;
