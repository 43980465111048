import { FC, useCallback, useMemo } from 'react';
import { CompassFilled, ClockCircleFilled } from '@ant-design/icons';
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getLocalizedDateTimeString } from 'shared/helpers/date';
import { useGetTreasureQuery } from 'services/treasures';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './index.module.sass';

type Props = {
  id: string;
};

const TreasureDetailsContainer: FC<Props> = ({ id }) => {
  const { data: treasureDetails, isSuccess: isGetTreasureSuccess } = useGetTreasureQuery(id, {
    skip: !!!id,
  });

  const modules = useMemo(
    () =>
      treasureDetails && treasureDetails.pictures && treasureDetails.pictures.length > 1
        ? [Navigation, Pagination, A11y]
        : undefined,
    [treasureDetails]
  );

  const handlePictureClick = useCallback((pictureUrl: string) => {
    window.open(pictureUrl, '_blank', 'noreferrer');
  }, []);

  return (
    <div className={styles.content}>
      {isGetTreasureSuccess && treasureDetails && (
        <>
          {treasureDetails.pictures && treasureDetails.pictures.length > 0 && (
            <div>
              <Swiper
                modules={modules}
                navigation={treasureDetails.pictures.length > 1}
                pagination={{ clickable: true }}
                autoHeight={true}
                centeredSlides={true}
                loop={treasureDetails.pictures.length > 1}
              >
                {treasureDetails.pictures.map((picture: string) => (
                  <SwiperSlide key={picture}>
                    <div className={styles.carouselSlide}>
                      <img src={picture} alt="" onClick={() => handlePictureClick(picture)} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          <div className={styles.header}>
            <h1>{treasureDetails.title}</h1>
          </div>
          <div className={styles.subHeader}>
            <div>
              <ClockCircleFilled />
              {getLocalizedDateTimeString(treasureDetails.createdAt)}
            </div>
            <div>
              <CompassFilled />
              {treasureDetails.lat}, {treasureDetails.lng}
            </div>
          </div>
          <div>{treasureDetails.notes}</div>
        </>
      )}
    </div>
  );
};

export default TreasureDetailsContainer;
