import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useGetTreasureQuery } from 'services/treasures';
import { AppHead, AppLayout, ErrorContainer } from 'shared/components';
import { appRoutes } from 'shared/routes/app-routes';
import TreasureDetailsContainer from 'containers/app/treasures/TreasureDetailsContainer';
import TreasureActionsMenu from './components/TreasureActionsMenu';
import styles from './index.module.sass';

const TreasureDetails = () => {
  const { t } = useTranslation(['app']);
  const { treasureId: rawTreasureId } = useParams();
  const treasureId = rawTreasureId as string;
  const {
    data: treasureDetails,
    isSuccess: isGetTreasureSuccess,
    error: getTreasureError,
  } = useGetTreasureQuery(treasureId, {
    skip: !!!treasureId,
  });

  return (
    <AppLayout>
      <AppHead title={treasureDetails?.title} />
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={appRoutes.hunts()}>{t('app:hunts.hunts')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={appRoutes.hunt(treasureDetails?.huntId)}>{treasureDetails?.huntTitle}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{treasureDetails?.title}</Breadcrumb.Item>
          </Breadcrumb>

          {isGetTreasureSuccess && treasureDetails && (
            <div className={styles.editButton}>
              <TreasureActionsMenu treasureDetails={treasureDetails} />
            </div>
          )}
        </div>
        <ErrorContainer error={getTreasureError} className={styles.errorContainer} />

        {isGetTreasureSuccess && treasureDetails && <TreasureDetailsContainer id={treasureId} />}
      </div>
    </AppLayout>
  );
};

export default TreasureDetails;
