import { message } from 'antd';

export enum NotificationType {
  SUCCESS,
  ERROR,
}

export const notification = (content: string | string[], type: NotificationType) => {
  switch (type) {
    case NotificationType.ERROR: {
      message.error(content);
      break;
    }
    case NotificationType.SUCCESS:
    default: {
      message.success(content);
      break;
    }
  }
};
