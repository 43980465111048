import { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { useProfileDetailsQuery } from 'services/profile';
import { Loading, AppSider } from 'shared/components';
import { useUser } from 'shared/hooks';
import { publicRoutes } from 'shared/routes/public-routes';
import styles from './index.module.sass';

const AppLayout = ({ children }: PropsWithChildren<{}>) => {
  // Redirect to login page if user is not connected
  const user = useUser({ redirectTo: publicRoutes.login() });
  const { isLoading, isFetching, isSuccess } = useProfileDetailsQuery({});
  // const [showAlert, setShowAlert] = useState(true);

  return (
    <Layout className={styles.layout} hasSider={true}>
      <AppSider />
      <Layout className={styles.contentLayout}>
        {/* {showAlert && profileDetails && profileDetails.status === UserStatus.PENDING && ( */}
        {/*   <div className={styles.alert}> */}
        {/*     <div> */}
        {/*       Hello, welcome to Poucher! You can try the app now and when you're ready to begin treasure hunting, please{' '} */}
        {/*       <Link to={publicRoutes.confirmationEmail('test@tes.com')}>confirm your email</Link> so we can know that you're not a robot. */}
        {/*     </div> */}
        {/*     <button onClick={() => setShowAlert(false)}> */}
        {/*       <FontAwesomeIcon icon={faXmark} /> */}
        {/*     </button> */}
        {/*   </div> */}
        {/* )} */}
        {(isLoading || isFetching || !user) && <Loading />}
        {user && user.isLoggedIn && isSuccess && (
          <Layout.Content>
            <div className={styles.contentWrapper}>{children}</div>
          </Layout.Content>
        )}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
