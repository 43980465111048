import { FC, useState, useEffect, SetStateAction, Dispatch, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useAppSelector } from 'store/store';
import { selectDetails } from 'services/profile';
import { getBase64 } from 'shared/helpers/image';
import styles from './index.module.sass';

export type FileData = UploadFile & { file?: File };

type Props = {
  filesToUpload: FileData[];
  setFilesToUpload: Dispatch<SetStateAction<FileData[]>>;
  pictures?: string[];
};

const PictureUploader: FC<Props> = ({ filesToUpload, setFilesToUpload, pictures }) => {
  const { t } = useTranslation(['app']);
  const { profileDetails } = useAppSelector((state) => selectDetails(state));
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorMessageKeys, setErrorMessageKeys] = useState<string[]>([]);

  // Update file info lists when removing a file
  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      if (info.file.status === 'removed') {
        setFileList(info.fileList);
        setFilesToUpload((prevFileDataList: FileData[]) =>
          prevFileDataList.filter((fileData) => fileData.uid !== info.file.uid)
        );
      }
    },
    [setFilesToUpload]
  );

  const handleBeforeUpload = useCallback(
    (file: File, currentFileList: File[]) => {
      if (!!!profileDetails) {
        return false;
      }

      const pictureErrorMessages: string[] = [];

      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        pictureErrorMessages.push('jpgOrPngError');
      }

      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        pictureErrorMessages.push('lt4MBError');
      }

      const hasReachedLimit =
        fileList.length + currentFileList.length > profileDetails.picturesPerTreasure;
      if (hasReachedLimit) {
        pictureErrorMessages.push('pictureLimitError');
      }

      if (!isLt4M || !isJpgOrPng || hasReachedLimit) {
        if (pictureErrorMessages.length > 0) {
          setErrorMessageKeys(pictureErrorMessages);
        }
        return false;
      }

      getBase64(file, (url) => {
        setFilesToUpload((prevFileInfo) => [
          ...prevFileInfo,
          {
            name: file.name,
            url,
            // @ts-ignore
            uid: file.uid,
            file,
          },
        ]);
      });

      return false;
    },
    [fileList.length, setFilesToUpload, profileDetails]
  );

  useEffect(() => {
    if (!filesToUpload) {
      return;
    }

    setFileList(
      filesToUpload.map((fileToUpload) => {
        return {
          name: fileToUpload.file ? fileToUpload.file.name : fileToUpload.uid,
          status: 'done',
          uid: fileToUpload.uid,
          url: fileToUpload.url,
        };
      })
    );
  }, [filesToUpload]);

  //   useEffect(() => {
  //     if (!pictures) {
  //       return;
  //     }

  //     setFileList(
  //       pictures.map((picture) => {
  //         return {
  //           name: picture,
  //           status: 'done',
  //           uid: picture,
  //           url: picture,
  //         };
  //       })
  //     );
  //   }, []);

  // Display error messages
  useEffect(() => {
    if (errorMessageKeys.length <= 0 || !!!profileDetails) {
      return;
    }

    for (const messageKey of errorMessageKeys) {
      if (messageKey === 'pictureLimitError') {
        message.error(
          t('app:treasures.pictureLimitError', { count: profileDetails.picturesPerTreasure })
        );
        continue;
      }

      message.error(t(`app:treasures.${messageKey}`));
    }

    setErrorMessageKeys([]);
  }, [errorMessageKeys, profileDetails, t]);

  return (
    <div className={styles.upload}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
        maxCount={profileDetails ? profileDetails.picturesPerTreasure : 0}
        multiple={true}
      >
        {fileList.length >= (profileDetails ? profileDetails.picturesPerTreasure : 0) ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Add Picture</div>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default PictureUploader;
