import { useSearchParams } from 'react-router-dom';
import HuntsContainer from 'containers/app/hunts/HuntsContainer';

const Hunts = () => {
  const [searchParams] = useSearchParams();
  const pageNumber = parseInt(searchParams.get('page') || '1');

  return <HuntsContainer page={pageNumber} />;
};

export default Hunts;
