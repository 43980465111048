import { FC, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import styles from './index.module.sass';

type Props = {
  setLocation: (location: google.maps.LatLng) => void;
  initialValue?: google.maps.LatLng;
};

const LocationSelector: FC<Props> = ({ setLocation, initialValue }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [initialCenter, setInitialCenter] = useState<google.maps.LatLng>();
  const [initialZoom, setInitialZoom] = useState(13);
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng>();

  const defaultCenter = useMemo(() => new google.maps.LatLng(0, 0), []);

  const setDefaultPosition = useCallback(() => {
    setInitialZoom(1);
    setInitialCenter(defaultCenter);
    setMarkerPosition(defaultCenter);
  }, [defaultCenter]);

  const getPositionSuccessCallback = useCallback((position: GeolocationPosition) => {
    const currentPosition = new google.maps.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    setInitialCenter(currentPosition);
    setMarkerPosition(currentPosition);
  }, []);

  const getPositionErrorCallback = useCallback(() => {
    if (navigator.permissions) {
      // Navigator has permissions available

      navigator.permissions.query({ name: 'geolocation' }).then((res) => {
        if (res.state === 'denied') {
          // Permission has been denied
        }
        setDefaultPosition();
      });
    } else {
      // Navigator has no permissions available
      setDefaultPosition();
    }
  }, [setDefaultPosition]);

  // Get user position
  useEffect(() => {
    if (initialValue) {
      setInitialCenter(initialValue);
      setMarkerPosition(initialValue);
      return;
    }

    if (navigator.geolocation) {
      // Geolocation is available
      navigator.geolocation.getCurrentPosition(
        getPositionSuccessCallback,
        getPositionErrorCallback
      );
    } else {
      // Geolocation is not available
      setDefaultPosition();
    }
    // eslint-disable-next-line
  }, []);

  // Initialize map
  useEffect(() => {
    if (!!!ref.current || !!map || !!!initialCenter) {
      return;
    }

    const googleMap = new window.google.maps.Map(ref.current, {
      center: initialCenter,
      zoom: initialZoom,
      tilt: 0,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      streetViewControl: false,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
      ],
    });

    setMap(googleMap);

    google.maps.event.addListener(googleMap, 'click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        setMarkerPosition(event.latLng);
      }
    });
  }, [ref, map, initialCenter, initialZoom]);

  // Initialize marker
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!marker) {
      setMarker(
        new google.maps.Marker({
          map: map,
          icon: '/assets/marker_add.png',
        })
      );
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, map]);

  // Update marker position
  useEffect(() => {
    if (!marker || !markerPosition) {
      return;
    }

    marker.setPosition(markerPosition);
    setLocation(markerPosition);
  }, [marker, markerPosition, setLocation]);

  return <div ref={ref} className={styles.mapContainer} />;
};

export default LocationSelector;
