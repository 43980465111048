import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useChangePasswordMutation } from 'services/profile';
import { AppHead, AppLayout } from 'shared/components';
import { notification, NotificationType } from 'shared/helpers/notification';
import { appRoutes } from 'shared/routes/app-routes';
import { Profile } from 'models/profile';
import styles from './index.module.sass';

const ChangePasswordContainer = () => {
  const { t } = useTranslation(['app', 'error']);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<Array<string>>();
  const [changePassword] = useChangePasswordMutation();

  const handleSubmit = useCallback(
    (values: Profile.ChangePasswordFormValues) => {
      changePassword(values)
        .unwrap()
        .then(() => {
          notification(t('app:profile.passwordChangedSuccessfully'), NotificationType.SUCCESS);
          navigate(appRoutes.profile());
        })
        .catch(({ data }) => {
          if (data && data.errorCodes) {
            setAlerts(data.errorCodes.map((code: number) => t(`error:${code}`)));
          } else {
            setAlerts([t('error:unknown')]);
          }
        });
    },
    [changePassword, navigate, t]
  );

  return (
    <AppLayout>
      <AppHead />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{t('app:profile.changePassword')}</h1>
        </div>

        <div className={styles.alertsWrapper}>
          {alerts &&
            alerts.map((alert) => (
              <Alert message="" description={alert} key={alert} type="error" />
            ))}
        </div>

        <Form
          id="changePasswordForm"
          size="large"
          name="changePassword"
          onFinish={handleSubmit}
          layout="vertical"
          className={styles.form}
          form={form}
        >
          <Form.Item
            name="passwordCurrent"
            rules={[
              { required: true, message: t('app:profile.currentPasswordValidation') },
              { min: 8, message: t('auth:passwordValidationMinLength') },
              { max: 72, message: t('auth:passwordValidationMaxLength') },
            ]}
          >
            <Input.Password
              placeholder={t('app:profile.currentPasswordPlaceholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item
            name="passwordNew"
            rules={[
              { required: true, message: t('app:profile.newPasswordValidation') },
              { min: 8, message: t('auth:passwordValidationMinLength') },
              { max: 72, message: t('auth:passwordValidationMaxLength') },
            ]}
          >
            <Input.Password
              placeholder={t('app:profile.newPasswordPlaceholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item
            name="passwordNewConfirmation"
            rules={[
              { required: true, message: t('app:profile.newPasswordConfirmationValidation') },
              { min: 8, message: t('auth:passwordValidationMinLength') },
              { max: 72, message: t('auth:passwordValidationMaxLength') },
            ]}
          >
            <Input.Password
              placeholder={t('app:profile.newPasswordConfirmationPlaceholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={
                  !form.isFieldsTouched(true) ||
                  form.getFieldsError().some(({ errors }) => errors.length)
                }
              >
                {t('app:profile.changePassword')}
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </AppLayout>
  );
};

export default ChangePasswordContainer;
