import { poucherApi } from 'services';
import { Auth } from 'models/auth';

const extendedApi = poucherApi.injectEndpoints({
  endpoints: (build) => ({
    lookup: build.query({
      transformResponse: (_response, meta) => ({ status: meta?.response?.status }),
      providesTags: ['Auth'],
      query: () => ({
        url: '/auth/lookup',
        credentials: 'include',
      }),
    }),

    signup: build.mutation({
      query: (params: Auth.SignupFormValues) => ({
        url: '/auth/signup',
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
    }),

    resendConfirmationEmail: build.mutation({
      query: (email: string) => ({
        url: '/auth/resend-confirmation-email',
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),

    confirm: build.mutation({
      query: (token: string) => ({
        url: '/auth/confirm',
        method: 'POST',
        body: { token },
      }),
    }),

    login: build.mutation({
      invalidatesTags: ['Auth'],
      query: (params: Auth.LoginFormValues) => ({
        url: '/auth/login',
        method: 'POST',
        body: params,
        credentials: 'include',
      }),
    }),

    forgotPassword: build.mutation({
      query: (params: Auth.ForgotPasswordFormValues) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: params,
      }),
    }),

    recoverPassword: build.mutation({
      query: (params: Auth.RecoverPasswordFormValues) => ({
        url: '/auth/recover',
        method: 'POST',
        body: params,
      }),
    }),

    logout: build.query({
      query: () => ({
        url: '/auth/logout',
        credentials: 'include',
        method: 'DELETE',
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useLookupQuery,
  useSignupMutation,
  useResendConfirmationEmailMutation,
  useConfirmMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useRecoverPasswordMutation,
  useLogoutQuery,
} = extendedApi;

export default extendedApi;
