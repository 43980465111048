export const appRoutes = {
  base: () => '',
  home: () => appRoutes.hunts(),
  treasures: () => `${appRoutes.base()}/treasures`,
  treasuresPage: (huntId: string, page: number) => `${appRoutes.hunt(huntId)}?page=${page}`,
  treasure: (id?: string | string) => `${appRoutes.treasures()}/${id || ':treasureId'}`,
  hunts: () => `${appRoutes.base()}/hunts`,
  huntsPage: (page: number) => `${appRoutes.hunts()}?page=${page}`,
  hunt: (id?: string) => `${appRoutes.hunts()}/${id || ':huntId'}`,
  profile: () => `${appRoutes.base()}/profile`,
  changePassword: () => `${appRoutes.profile()}/change-password`,
  deleteAccount: () => `${appRoutes.profile()}/delete-account`,
  settings: () => `${appRoutes.base()}/settings`,
  upgrade: () => `${appRoutes.base()}/upgrade`,
  help: () => `${appRoutes.base()}/help`,
};
