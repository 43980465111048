import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';
import type { DefaultError } from 'services/types';

type Props = {
  error?: FetchBaseQueryError | SerializedError;
  className?: string;
};

const ErrorContainer: FC<Props> = ({ error, className }) => {
  const { t } = useTranslation(['error']);

  return error ? (
    <div className={className}>
      {'data' in error &&
        (error.data as DefaultError).errorCodes?.map((err: string) => (
          <Alert message="" description={t(`error:${err}`)} key={err} type="error" />
        ))}
      {'data' in error && !!!(error.data as DefaultError).errorCodes && (
        <Alert message="" description={t('error:unknown')} type="error" />
      )}
    </div>
  ) : null;
};

export default ErrorContainer;
