import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TreasurePlaceholder from 'assets/images/placeholder-treasure.webp';
import { appRoutes } from 'shared/routes/app-routes';
import { Treasure } from 'models/treasure';
import { CardHeader } from '../CardHeader';
import styles from './index.module.sass';

type Props = {
  treasure?: Treasure.Entity;
  page?: number;
  openEditModal?: (id: string) => void;
  loading?: boolean;
};

export const TreasureCard: FC<Props> = ({ treasure, page, openEditModal, loading = false }) => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();

  const handleTreasureClick = useCallback(() => {
    if (!treasure) {
      return;
    }

    navigate(appRoutes.treasure(treasure.id));
  }, [navigate, treasure]);

  if (!!!treasure || !!!page || !!!openEditModal) {
    return (
      <div className={styles.treasureCard}>
        <Card
          loading={true}
          cover={
            <div className={styles.thumbnail}>
              {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div key={treasure.id} className={styles.treasureCard}>
      <Card
        cover={
          <div className={styles.thumbnail} onClick={handleTreasureClick}>
            <img
              src={treasure.thumbnailUrl || TreasurePlaceholder}
              alt={t('app:treasures.defaultTreasure')}
            />
          </div>
        }
      >
        <Card.Meta
          title={
            <CardHeader
              title={treasure.title}
              id={treasure.id}
              huntId={treasure.huntId}
              currentPage={page}
              openEditModal={openEditModal}
            />
          }
          description={treasure.notes || ''}
        />
      </Card>
    </div>
  );
};
