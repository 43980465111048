import { useEffect, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { loadSettings } from 'store/settings/settings.thunk';
// All at once
import Home from './home';
// import {
//   Login,
//   Signup,
//   Confirm,
//   ConfirmationStatus,
//   ForgotPassword,
//   ForgotPasswordCheckEmail,
//   RecoverPassword,
// } from './auth';
import {
  Hunts,
  HuntDetails,
  TreasureDetails,
  Profile,
  // ChangePassword,
  // DeleteAccount,
  Settings,
  Upgrade,
  // Success,
  Help,
} from './app';

// Code splitting
// const Home = lazy(() => import('./home'));

const Login = lazy(() => import('./auth/login'));
const Signup = lazy(() => import('./auth/signup'));
const Confirm = lazy(() => import('./auth/confirm'));
const ConfirmationStatus = lazy(() => import('./auth/confirm/status'));
const ForgotPassword = lazy(() => import('./auth/forgot-password'));
const ForgotPasswordCheckEmail = lazy(() => import('./auth/forgot-password/check-email'));
const RecoverPassword = lazy(() => import('./auth/recover-password'));

// const Hunts = lazy(() => import('./app/hunts'));
// const HuntDetails = lazy(() => import('./app/hunts/details'));
// const TreasureDetails = lazy(() => import('./app/treasures/details'));
// const Profile = lazy(() => import('./app/profile'));
const ChangePassword = lazy(() => import('./app/profile/change-password'));
const DeleteAccount = lazy(() => import('./app/profile/delete-account'));
// const Settings = lazy(() => import('./app/settings'));
// const Upgrade = lazy(() => import('./app/upgrade'));
const Success = lazy(() => import('./app/upgrade/success'));
// const Help = lazy(() => import('./app/help'));

const MainRouter = () => {
  const dispatch = useAppDispatch();

  // Load user settings to store
  useEffect(() => {
    dispatch(loadSettings());
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        {/* <Route path="pricing" element={<Pricing />} /> */}
        {/* <Route path="come-back-soon" element={<ComeBackSoon />} /> */}
        <Route path="auth">
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="confirm/:token" element={<Confirm />} />
          <Route path="confirm/status" element={<ConfirmationStatus />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password/check-email" element={<ForgotPasswordCheckEmail />} />
          <Route path="recover/:token" element={<RecoverPassword />} />
        </Route>
        <Route path="hunts">
          <Route index element={<Hunts />} />
          <Route path=":huntId" element={<HuntDetails />} />
        </Route>
        <Route path="treasures">
          <Route path=":treasureId" element={<TreasureDetails />} />
        </Route>
        <Route path="profile">
          <Route index element={<Profile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="delete-account" element={<DeleteAccount />} />
        </Route>
        <Route path="settings" element={<Settings />} />
        <Route path="upgrade">
          <Route index element={<Upgrade />} />
          <Route path="success" element={<Success />} />
        </Route>
        <Route path="help" element={<Help />} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
