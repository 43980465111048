import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { appRoutes } from 'shared/routes/app-routes';
import styles from './index.module.sass';

type Props = {
  pictureUrl?: string;
  huntId?: string;
  loading?: boolean;
};

const CardCover: FC<Props> = ({ pictureUrl, huntId, loading = false }) => {
  const { t } = useTranslation(['app']);

  if (!huntId || !pictureUrl) {
    return (
      <div className={styles.coverPicture}>
        {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} />}
      </div>
    );
  }

  return (
    <Link to={appRoutes.hunt(huntId)}>
      <div className={styles.coverPicture}>
        {!loading && <img src={pictureUrl} alt={t('app:hunts.hunt')} />}
      </div>
    </Link>
  );
};

export default CardCover;
