import { useTranslation } from 'react-i18next';
import { AppHead, AppLayout } from 'shared/components';
import styles from './index.module.sass';

const Help = () => {
  const { t } = useTranslation(['help']);

  return (
    <AppLayout>
      <AppHead title={t('help:help')} />
      <div className={styles.content}>
        <h1>{t('help:help')}</h1>
      </div>
    </AppLayout>
  );
};

export default Help;
