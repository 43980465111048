import { Treasure } from 'models/treasure';

export namespace Hunt {
  export type Entity = {
    id: string;
    title: string;
    date: string;
    description?: string;
    coverPictureUrl?: string;
    createdAt: string;
    updatedAt?: string;
  };

  export interface CreateFormValues {
    title: string;
    dateString: string;
    date: string;
    description?: string;
    coverPicture?: null | File;
  }

  export interface Details {
    title: string;
    treasures: Treasure.Entity[];
  }
}

export enum HuntsFilter {
  RECENT = 'RECENT',
  OLDEST = 'OLDEST',
}
