import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLookupQuery } from 'services/auth';

type Props = {
  redirectTo?: string;
  redirectIfFound?: boolean;
};

const useUser = ({ redirectTo, redirectIfFound = false }: Props) => {
  const { isError, isSuccess, isLoading, isFetching } = useLookupQuery('');
  const [user, setUser] = useState<null | { isLoggedIn: boolean }>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || isFetching) {
      return;
    }

    if (isSuccess) {
      setUser({ isLoggedIn: true });

      if (redirectTo && redirectIfFound) {
        navigate(redirectTo);
      }
    } else if (isError) {
      setUser({ isLoggedIn: false });

      if (redirectTo && !redirectIfFound) {
        navigate(redirectTo);
      }
    }
  }, [navigate, redirectTo, redirectIfFound, isSuccess, isError, isLoading, isFetching]);

  return user;
};

export default useUser;
