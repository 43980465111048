import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { poucherApi } from 'services';
import settingsReducer from 'store/settings/settings.slice';
import ordersReducer from 'store/orders/orders.slice';

export const configureStoreOptions = {
  reducer: {
    settings: settingsReducer,
    orders: ordersReducer,
    [poucherApi.reducerPath]: poucherApi.reducer,
  },
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat(poucherApi.middleware),
};

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    orders: ordersReducer,
    [poucherApi.reducerPath]: poucherApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(poucherApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
