import { useEffect, useState, useCallback, FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Modal, Form, Input, Button } from 'antd';
import Icon from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { useCreateTreasureMutation } from 'services/treasures';
import { Treasure } from 'models/treasure';
import { MarkerSvg } from 'shared/resources/images';
import { PicturesSvg } from 'shared/resources/images';
import { TreasureTestIds } from 'shared/constants/test-ids';
import { LocationSelector, ErrorContainer } from 'shared/components';
import PictureUploader, { FileData } from 'shared/components/PictureUploader';
import styles from 'shared/styles/treasure-form.module.sass';

const MarkerIcon = () => <Icon component={MarkerSvg} />;

const PicturesIcon = () => <Icon component={PicturesSvg} />;

type Props = {
  huntId: string;
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const TreasureCreateForm: FC<Props> = ({ onClose, isLoading, setIsLoading, huntId }) => {
  const { t } = useTranslation(['translation', 'app']);
  const [createTreasure, { isLoading: isCreateTreasureLoading, error: createTreasureError }] =
    useCreateTreasureMutation();
  const [form] = Form.useForm();
  const [showLocationSelector, setShowLocationSelector] = useState<boolean>(false);
  const [temporaryLocation, setTemporaryLocation] = useState<google.maps.LatLng>();
  const [treasureLocation, setTreasureLocation] = useState<google.maps.LatLng>();
  const [showPictureUploader, setShowPictureUploader] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<FileData[]>([]);
  const [numberOfPictures, setNumberOfPictures] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openLocationSelector = () => setShowLocationSelector(true);

  const closeLocationSelector = () => setShowLocationSelector(false);

  const openPictureUploader = () => setShowPictureUploader(true);

  const closePictureUploader = () => setShowPictureUploader(false);

  // Handle form submit
  const handleSubmit = useCallback(
    async (values: Treasure.CreateFormValues) => {
      setIsSubmitting(true);

      if (filesToUpload.length > 0) {
        values.pictures = await Promise.all(
          filesToUpload
            .filter((fileToUpload) => {
              return fileToUpload.file != null;
            })
            .map(async (fileToUpload) => {
              // Select target max file size in MB
              const coverPictureFileSizeInMb = fileToUpload.file!.size / 1024 / 1024;
              const maxFileSize = Math.min(coverPictureFileSizeInMb / 2, 0.5);
              const compressedFile = await imageCompression(fileToUpload.file!, {
                maxSizeMB: maxFileSize,
                maxIteration: 100,
                useWebWorker: true,
              });
              return compressedFile;
            })
        );
      }

      if (values.location) {
        values.latitude = values.location.lat();
        values.longitude = values.location.lng();
        delete values.location;
      }

      values.huntId = huntId;

      createTreasure(values)
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [huntId, filesToUpload, onClose, createTreasure, setIsLoading]
  );

  const handleSelectLocation = useCallback(() => {
    if (!temporaryLocation) {
      return;
    }

    const currentFieldsValues = form.getFieldsValue();

    form.setFieldsValue({
      ...currentFieldsValues,
      location: temporaryLocation,
    });

    setTreasureLocation(temporaryLocation);

    closeLocationSelector();
  }, [temporaryLocation, form]);

  useEffect(() => {
    setNumberOfPictures(filesToUpload.length);
  }, [filesToUpload]);

  // Set current location as initial value
  useEffect(() => {
    if (!!treasureLocation) {
      return;
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latLng = new window.google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        form.setFieldsValue({
          location: latLng,
        });
        setTreasureLocation(latLng);
      });
    }

    // Reason: 'form' dependency is calling this function a lot
    // eslint-disable-next-line
  }, [treasureLocation]);

  useEffect(() => {
    setIsLoading(isCreateTreasureLoading || isSubmitting);
    // eslint-disable-next-line
  }, [isCreateTreasureLoading, isSubmitting]);

  return (
    <>
      <div className={styles.form}>
        <Form
          id="treasure-create"
          form={form}
          size="large"
          name="createTreasure"
          onFinish={handleSubmit}
          layout="vertical"
          preserve={false}
          className={styles.form}
        >
          <ErrorContainer error={createTreasureError} className={styles.errorContainer} />
          <Form.Item
            name="title"
            rules={[
              { required: true, message: t('app:treasures.titleValidation') },
              { min: 2, message: t('app:treasures.titleValidationMinLength') },
              { max: 100, message: t('app:treasures.titleValidationMaxLength') },
            ]}
          >
            <Input
              placeholder={t('app:treasures.titlePlaceholder')}
              disabled={isLoading}
              data-testid={TreasureTestIds.titleInput}
              maxLength={100}
            />
          </Form.Item>

          <Form.Item
            name="notes"
            rules={[
              { required: false },
              { max: 10000, message: t('app:treasures.notesValidationMaxLength') },
            ]}
          >
            <Input.TextArea
              placeholder={t('app:treasures.notesPlaceholder')}
              maxLength={10000}
              disabled={isLoading}
              data-testid={TreasureTestIds.descriptionInput}
            />
          </Form.Item>

          <div className={styles.formInputButtonWrapper} onClick={openLocationSelector}>
            <Form.Item name="location">
              <div className={styles.inputButton}>
                <MarkerIcon />
                <div>
                  <h4>{t('app:treasures.location')}</h4>
                  {treasureLocation ? (
                    <span>
                      {treasureLocation.lat()}, {treasureLocation.lng()}
                    </span>
                  ) : (
                    <span>{t('app:treasures.selectLocation')}</span>
                  )}
                </div>
              </div>
            </Form.Item>
          </div>

          <div className={styles.formInputButtonWrapper} onClick={openPictureUploader}>
            <Form.Item
              name="pictures"
              rules={[{ required: false, message: t('app:treasures.picturesValidation') }]}
            >
              <div className={styles.inputButton}>
                <PicturesIcon />
                <div>
                  <h4>{t('app:treasures.picturesTitle')}</h4>
                  {numberOfPictures === 0 ? (
                    <span>{t('app:treasures.noPicturesSelected')}</span>
                  ) : (
                    <span>{t('app:treasures.picturesSelected', { count: numberOfPictures })}</span>
                  )}
                </div>
              </div>
            </Form.Item>
          </div>

          <div className={styles.buttons}>
            <Form.Item>
              <Button key="cancel" onClick={onClose} disabled={isLoading} size="middle">
                {t('common.cancel')}
              </Button>
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={isLoading}
                  disabled={
                    !form.isFieldsTouched(['title']) ||
                    form.getFieldsError().some(({ errors }) => errors.length)
                  }
                >
                  {t('common.add')}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>

      {/* Location Selector Sub Modal */}
      <Modal
        title={t('app:treasures.selectLocation')}
        visible={showLocationSelector}
        onCancel={closeLocationSelector}
        className={styles.locationSelectorModal}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={closeLocationSelector}>
            {t('common.cancel')}
          </Button>,
          <Button key="select" type="primary" onClick={handleSelectLocation}>
            {t('common.select')}
          </Button>,
        ]}
      >
        <div className={styles.mapWrapper}>
          <LocationSelector setLocation={setTemporaryLocation} initialValue={treasureLocation} />
        </div>
      </Modal>

      {/* Picture Uploader Sub Modal */}
      <Modal
        title={t('app:treasures.selectPictures')}
        visible={showPictureUploader}
        onCancel={closePictureUploader}
        className={styles.pictureUploaderModal}
        destroyOnClose={true}
        closable={false}
        footer={[
          <Button key="done" type="primary" onClick={closePictureUploader}>
            {t('common.done')}
          </Button>,
        ]}
      >
        <div>
          <PictureUploader filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} />
        </div>
      </Modal>
    </>
  );
};

const TreasureCreateFormWrapper: FC<Props> = (props) => {
  const render = (_status: Status) => {
    return <span />;
  };

  return (
    <Wrapper apiKey="AIzaSyA2Y-WOMt3R332LZcesEY8sYV1eI8Cma58" render={render}>
      <TreasureCreateForm {...props} />
    </Wrapper>
  );
};

export default TreasureCreateFormWrapper;
