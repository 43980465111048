import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { useCheckoutMutation } from 'services/payment';
import { AppHead, UpgradeTiers } from 'shared/components';
import { publicRoutes } from 'shared/routes/public-routes';
import { useUser } from 'shared/hooks';
import styles from './index.module.sass';

const UpgradeContainer = () => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();
  useUser({ redirectTo: publicRoutes.login() });
  const [checkout, { isLoading: isCheckoutLoading }] = useCheckoutMutation();

  const onSelect = useCallback(
    (priceId: string) => {
      if (priceId == null) {
        return;
      }

      checkout(priceId)
        .unwrap()
        .then((data) => {
          if (!!data && !!data.url) {
            window.location.href = data.url;
          } else {
            console.error('Error generating stripe session url.');
          }
        });
    },
    [checkout]
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles.wrapper}>
      <Layout>
        <AppHead />
        <div className={styles.content}>
          <div className={styles.goBack} onClick={handleGoBack}>
            ← Go back
          </div>
          <main>
            <div className={styles.headings}>
              <h1>{t('app:upgrade.appHeading')}</h1>
              <h2>{t('app:upgrade.appSubHeading')}</h2>
            </div>

            <div className={styles.upgradeTiers}>
              <UpgradeTiers onSelect={onSelect} loading={isCheckoutLoading} />
            </div>
          </main>
        </div>
      </Layout>
    </div>
  );
};

export default UpgradeContainer;
