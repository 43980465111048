import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { createOrder } from 'store/orders/orders.thunk';

type OrdersState = {
  signupOrder: string;
};

const initialState: OrdersState = {
  signupOrder: 'free',
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrder.fulfilled, (state, action) => {
      const { orderKey } = action.payload;
      state.signupOrder = orderKey;
    });
  },
});

export const selectSignupOrder = (state: RootState) => state.orders.signupOrder;

export default ordersSlice.reducer;
