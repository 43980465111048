import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { appRoutes } from 'shared/routes/app-routes';
import { TreasureTestIds } from 'shared/constants/test-ids';
import { useDeleteTreasureMutation } from 'services/treasures';
import styles from './index.module.sass';

type Props = {
  title: string;
  id: string;
  huntId: string;
  currentPage: number;
  openEditModal: (id: string) => void;
};

export const CardHeader: FC<Props> = ({ title, id, huntId, currentPage, openEditModal }) => {
  const { t } = useTranslation(['translation', 'app']);
  const [deleteTreasure] = useDeleteTreasureMutation();

  const showDeleteConfirm = useCallback(() => {
    Modal.confirm({
      title: t('app:treasures.deleteConfirmationTitle'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common.delete'),
      okType: 'danger',
      cancelText: t('common.cancel'),
      onOk() {
        deleteTreasure({
          treasureId: id,
          huntId,
          page: currentPage,
          pagination: true,
        });
      },
    });
  }, [id, deleteTreasure, currentPage, huntId, t]);

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            label: t('common.edit'),
            key: 'edit',
            onClick: () => openEditModal(id),
          },
          {
            label: t('common.delete'),
            key: 'delete',
            onClick: showDeleteConfirm,
          },
        ]}
      />
    ),
    [id, openEditModal, showDeleteConfirm, t]
  );

  return (
    <div className={styles.cardHeader}>
      <div className={styles.titleRow}>
        <Link to={appRoutes.treasure(id)}>{title}</Link>
        <Dropdown overlay={menu} placement="top" trigger={['click']} arrow>
          <div className={styles.iconButton}>
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              data-testid={TreasureTestIds.actionsButton}
            />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
