import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { AppHead, AppLayout } from 'shared/components';
import { publicRoutes } from 'shared/routes/public-routes';
import { notification, NotificationType } from 'shared/helpers/notification';
import { ProfileTestIds } from 'shared/constants/test-ids';
import { useAppDispatch } from 'store/store';
import { poucherApi } from 'services';
import { useDeleteAccountMutation } from 'services/profile';
import { Profile } from 'models/profile';
import styles from './index.module.sass';

const DeleteAccountContainer = () => {
  const { t } = useTranslation(['app', 'auth']);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [deleteAccount] = useDeleteAccountMutation();

  const handleSubmit = useCallback(
    (values: Profile.DeleteAccountFormValues) => {
      deleteAccount(values)
        .unwrap()
        .then(() => {
          dispatch(poucherApi.util.resetApiState());
          notification(t('app:profile.accountDeletedNotification'), NotificationType.SUCCESS);
          navigate(publicRoutes.login());
        });
    },
    [deleteAccount, dispatch, navigate, t]
  );

  return (
    <AppLayout>
      <AppHead />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{t('app:profile.deleteAccount')}</h1>
        </div>

        <Form
          id="deleteAccountForm"
          size="large"
          name="deleteAccount"
          onFinish={handleSubmit}
          layout="vertical"
          className={styles.form}
          form={form}
        >
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t('auth:passwordValidationRequired') },
              { min: 8, message: t('auth:passwordValidationMinLength') },
              { max: 72, message: t('auth:passwordValidationMaxLength') },
            ]}
          >
            <Input.Password
              placeholder={t('auth:passwordPlaceholder')}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              data-testid={ProfileTestIds.passwordInput}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={
                  !form.isFieldsTouched(true) ||
                  form.getFieldsError().some(({ errors }) => errors.length)
                }
              >
                {t('app:profile.confirm')}
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </AppLayout>
  );
};

export default DeleteAccountContainer;
