import { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { OutlinedButton } from 'shared/components';
import { appRoutes } from 'shared/routes/app-routes';

type Props = {
  className?: string;
  label?: string;
};

const UpgradeButton: FC<Props> = ({ className, label }) => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();

  const handleUpgradeClick = useCallback(() => {
    navigate(appRoutes.upgrade());
  }, [navigate]);

  return (
    <OutlinedButton
      onClick={handleUpgradeClick}
      icon={<StarFilled />}
      color="blue"
      className={className}
    >
      {label ?? t('app:upgrade.upgrade')}
    </OutlinedButton>
  );
};

export default UpgradeButton;
