import { useCallback, useMemo, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { usePricesQuery } from 'services/payment';
import { useProfileDetailsQuery } from 'services/profile';
import { PriceCard } from 'shared/components';
import Tier from 'shared/constants/tier';
import hobbyistIcon from 'assets/icons/upgrade/hobbyist.png';
import hunterIcon from 'assets/icons/upgrade/hunter.png';
import hoarderIcon from 'assets/icons/upgrade/hoarder.png';
import styles from './index.module.sass';

export enum PaymentType {
  HOBBYIST = 'hobbyist',
  HUNTER = 'hunter',
  HOARDER = 'hoarder',
}

type Props = {
  onSelect: (priceId: string) => void;
  loading?: boolean;
};

const UpgradeTiers: FC<Props> = ({ onSelect, loading = false }) => {
  const { t } = useTranslation(['translation', 'app']);
  const { data: profileDetails } = useProfileDetailsQuery({});
  const [billingInterval, setBillingInterval] = useState<'month' | 'year'>(
    profileDetails?.billingInterval ?? 'year'
  );

  const {
    data: pricesData,
    isLoading: isPricesDataLoading,
    isFetching: isPricesDataFetching,
  } = usePricesQuery({});

  const isPricesLoading = isPricesDataLoading || isPricesDataFetching;

  const toggleYearlyPrices = useCallback((checked: boolean) => {
    setBillingInterval(checked ? 'year' : 'month');
  }, []);

  const prices = useMemo(() => {
    if (!!!pricesData || isPricesLoading) {
      return null;
    }

    const period = t(`common.${billingInterval}`);

    const hobbyistString: 'hobbyist_yearly' | 'hobbyist_monthly' = `hobbyist_${billingInterval}ly`;
    const hunterString: 'hunter_yearly' | 'hunter_monthly' = `hunter_${billingInterval}ly`;
    const hoarderString: 'hoarder_yearly' | 'hoarder_monthly' = `hoarder_${billingInterval}ly`;

    return [
      {
        value: `${pricesData.prices[hobbyistString]?.amountString}/${period}`,
        rawValue: pricesData.prices[hobbyistString]?.amountString || '',
        id: pricesData.prices[hobbyistString]?.priceId,
      },
      {
        value: `${pricesData.prices[hunterString]?.amountString}/${period}`,
        rawValue: pricesData.prices[hunterString]?.amountString || '',
        id: pricesData.prices[hunterString]?.priceId,
      },
      {
        value: `${pricesData.prices[hoarderString]?.amountString}/${period}`,
        rawValue: pricesData.prices[hoarderString]?.amountString || '',
        id: pricesData.prices[hoarderString]?.priceId,
      },
    ];
  }, [isPricesLoading, billingInterval, pricesData, t]);

  if (profileDetails == null) {
    return <div />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.yearlyOption}>
        <span>{t('app:upgrade.yearlyOptionContent')}</span>
        <Switch checked={billingInterval === 'year'} onChange={toggleYearlyPrices} size="small" />
      </div>

      <div className={styles.prices}>
        {prices && prices[0] && (
          <PriceCard
            title={t('app:upgrade.hobbyist')}
            content={t('app:upgrade.hobbyistContent')}
            cardTier={Tier.HOBBYIST}
            currentTier={profileDetails.tier}
            isCurrentPlan={
              profileDetails.tier === Tier.HOBBYIST &&
              profileDetails.billingInterval === billingInterval
            }
            priceValue={prices[0].value}
            rawPriceValue={prices[0].rawValue}
            priceId={prices[0].id}
            isBestOffer={false}
            loading={loading || isPricesLoading}
            onSelect={onSelect}
            icon={<img src={hobbyistIcon} alt="" />}
            paymentType={profileDetails.paymentType}
          />
        )}
        {prices && prices[1] && (
          <PriceCard
            title={t('app:upgrade.hunter')}
            content={t('app:upgrade.hunterContent')}
            cardTier={Tier.HUNTER}
            currentTier={profileDetails.tier}
            isCurrentPlan={
              profileDetails.tier === Tier.HUNTER &&
              profileDetails.billingInterval === billingInterval
            }
            priceValue={prices[1].value}
            rawPriceValue={prices[1].rawValue}
            priceId={prices[1].id}
            isBestOffer={true}
            loading={loading || isPricesLoading}
            onSelect={onSelect}
            icon={<img src={hunterIcon} alt="" />}
            paymentType={profileDetails.paymentType}
          />
        )}
        {prices && prices[2] && (
          <PriceCard
            title={t('app:upgrade.hoarder')}
            content={t('app:upgrade.hoarderContent')}
            cardTier={Tier.HOARDER}
            currentTier={profileDetails.tier}
            isCurrentPlan={
              profileDetails.tier === Tier.HOARDER &&
              profileDetails.billingInterval === billingInterval
            }
            priceValue={prices[2].value}
            rawPriceValue={prices[2].rawValue}
            priceId={prices[2].id}
            isBestOffer={false}
            loading={loading || isPricesLoading}
            onSelect={onSelect}
            icon={<img src={hoarderIcon} alt="" />}
            paymentType={profileDetails.paymentType}
          />
        )}
      </div>
    </div>
  );
};

export default UpgradeTiers;
