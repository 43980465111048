import { poucherApi } from 'services';
import { transformDefaultResponse } from 'services/types';
import { PricesResponseData, CheckoutResponseData, PaymentStatusResponseData } from './types';

const extendedApi = poucherApi.injectEndpoints({
  endpoints: (build) => ({
    prices: build.query({
      transformResponse: transformDefaultResponse<PricesResponseData>(),
      query: () => ({
        url: '/payment/stripe/prices',
      }),
    }),

    checkout: build.mutation({
      transformResponse: transformDefaultResponse<CheckoutResponseData>(),
      query: (priceId: string) => ({
        url: '/payment/stripe/checkout',
        method: 'POST',
        body: { priceId },
        credentials: 'include',
      }),
    }),

    paymentStatus: build.query({
      transformResponse: transformDefaultResponse<PaymentStatusResponseData>(),
      query: () => ({
        url: '/payment/stripe/status',
        credentials: 'include',
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useCheckoutMutation, usePricesQuery } = extendedApi;
