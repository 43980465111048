import { poucherApi } from 'services';
import { transformDefaultResponse } from 'services/types';
import { Treasure } from 'models/treasure';
import {
  GetAllTreasuresArgs,
  GetAllTreasuresResponseData,
  UpdateTreasureArgs,
  DeleteTreasureArgs,
} from './types';

const extendedApi = poucherApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTreasures: build.query({
      transformResponse: transformDefaultResponse<GetAllTreasuresResponseData>(),
      providesTags: [{ type: 'Treasure', id: 'LIST' }],
      query: (args: GetAllTreasuresArgs) => {
        const params = [];

        params.push(`huntId=${args.huntId}`);

        if (args.page) {
          params.push(`page=${args.page}`);
        }

        if (args.pagination != null) {
          params.push(`pagination=${args.pagination}`);
        }

        // Get full params string
        let paramsString = '';

        if (params.length > 0) {
          paramsString = `?${params.reduce((p, c) => `${p}&${c}`)}`;
        }

        return {
          url: `/treasures${paramsString}`,
          credentials: 'include',
        };
      },
    }),

    getTreasure: build.query({
      transformResponse: transformDefaultResponse<Treasure.Details>(),
      providesTags: (_result, _error, arg) => [{ type: 'Treasure', id: arg as string }],
      query: (treasureId?: null | string) => ({
        url: `/treasures/${treasureId}`,
        credentials: 'include',
      }),
    }),

    createTreasure: build.mutation({
      invalidatesTags: [{ type: 'Treasure', id: 'LIST' }, 'TreasuresLeft'],
      query: (params: Treasure.CreateFormValues) => {
        const formData = new FormData();
        formData.append('title', params['title'] || '');
        formData.append('notes', params['notes'] || '');
        formData.append('latitude', params['latitude'].toString() || '');
        formData.append('longitude', params['longitude'].toString() || '');
        formData.append('huntId', params['huntId'].toString() || '');

        if (params['pictures']) {
          for (const picture of params['pictures']) {
            formData.append('pictures[]', picture);
          }
        }

        return {
          url: '/treasures',
          method: 'POST',
          body: formData,
          credentials: 'include',
        };
      },
    }),

    updateTreasure: build.mutation({
      invalidatesTags: (_result, _error, args) => [
        { type: 'Treasure', id: 'LIST' },
        { type: 'Treasure', id: args.treasureId },
      ],
      query: (args: UpdateTreasureArgs) => {
        const formData = new FormData();
        formData.append('title', args.params['title'] || '');
        formData.append('notes', args.params['notes'] || '');
        formData.append('latitude', args.params['latitude'].toString() || '');
        formData.append('longitude', args.params['longitude'].toString() || '');
        formData.append('huntId', args.params['huntId'].toString() || '');

        if (args.params['picturesToDelete']) {
          for (const pictureToDelete of args.params['picturesToDelete']) {
            formData.append('picturesToDelete[]', pictureToDelete);
          }
        }

        if (args.params['pictures']) {
          for (const picture of args.params['pictures']) {
            formData.append('pictures[]', picture);
          }
        }

        return {
          url: `/treasures/${args.treasureId}`,
          method: 'PUT',
          body: formData,
          credentials: 'include',
        };
      },
    }),

    deleteTreasure: build.mutation({
      invalidatesTags: [{ type: 'Treasure', id: 'LIST' }, 'TreasuresLeft'],
      query: (args: DeleteTreasureArgs) => ({
        url: `/treasures/${args.treasureId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      // onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     poucherApi.util.updateQueryData(
      //       // @ts-ignore
      //       'getAllTreasures',
      //       { huntId: args.huntId, page: args.page, pagination: args.pagination },
      //       (draft) => {
      //         // @ts-ignore
      //         const treasureIndex = draft.treasures.findIndex(
      //           (treasure: Treasure.Entity) => treasure.id === args.treasureId
      //         );
      //         if (treasureIndex !== -1) {
      //           // @ts-ignore
      //           draft.treasures.splice(treasureIndex, 1);
      //         }
      //       }
      //     )
      //   );
      //   queryFulfilled.catch(patchResult.undo);
      // },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllTreasuresQuery,
  useGetTreasureQuery,
  useCreateTreasureMutation,
  useUpdateTreasureMutation,
  useDeleteTreasureMutation,
} = extendedApi;
