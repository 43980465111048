import { useState, useEffect, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Select, Pagination } from 'antd';
import { Hunt, HuntsFilter } from 'models/hunt';
import { useGetAllHuntsQuery } from 'services/hunts';
import { useAppSelector } from 'store/store';
import { selectTier } from 'services/profile';
import { AppHead, AppLayout, UpgradeButton } from 'shared/components';
import { appRoutes } from 'shared/routes/app-routes';
import HuntPlaceholder from 'assets/images/placeholder-hunt.webp';
import { HuntTestIds } from 'shared/constants/test-ids';
import Tier from 'shared/constants/tier';
import { getLocalizedDateString } from 'shared/helpers/date';
import { HuntIllustration } from 'shared/resources/images';
import CardCover from 'containers/app/hunts/CardCover';
import CardHeader from 'containers/app/hunts/CardHeader';
import HuntCreateModal from 'containers/app/hunts/HuntCreateModal';
import HuntUpdateModal from 'containers/app/hunts/HuntUpdateModal';
import styles from './index.module.sass';

const HUNTS_PER_PAGE = 9;

type Props = {
  page: number;
};

const HuntsContainer: FC<Props> = ({ page = 1 }) => {
  const { t } = useTranslation(['app']);
  const navigate = useNavigate();
  const tier = useAppSelector((state) => selectTier(state));
  const [hunts, setHunts] = useState<null | Hunt.Entity[]>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filter, setFilter] = useState<HuntsFilter>(HuntsFilter.RECENT);
  const [showCreateHuntModal, setShowCreateHuntModal] = useState(false);
  const [showUpdateHuntModal, setShowUpdateHuntModal] = useState(false);
  const [huntId, setHuntId] = useState<null | string>(null);
  const {
    data: huntsData,
    isLoading: isLoadingHunts,
    isFetching: isFetchingHunts,
  } = useGetAllHuntsQuery({
    page: page ?? 1,
    filter,
  });

  const openCreateHuntModal = useCallback(() => {
    if (showUpdateHuntModal) {
      return;
    }

    setShowCreateHuntModal(true);
  }, [showUpdateHuntModal]);

  const closeCreateHuntModal = useCallback(() => {
    setShowCreateHuntModal(false);
  }, []);

  const openUpdateHuntModal = useCallback(
    (updatedHuntId: string) => {
      if (showCreateHuntModal) {
        return;
      }

      setHuntId(updatedHuntId);
      setShowUpdateHuntModal(true);
    },
    [showCreateHuntModal]
  );

  const closeUpdateHuntModal = useCallback(() => {
    setShowUpdateHuntModal(false);
    setHuntId(null);
  }, []);

  const handlePageChange = useCallback(
    (newPage: number) => {
      setHunts(null);
      document.body.scrollTo(0, 0);
      navigate(appRoutes.huntsPage(newPage));
    },
    [navigate]
  );

  const handleFilterChange = useCallback(
    (filterOption: HuntsFilter) => {
      setFilter(filterOption);

      if (page !== 1) {
        navigate(appRoutes.huntsPage(1));
      }
    },
    [page, navigate]
  );

  // Set hunts data
  useEffect(() => {
    if (!huntsData || isLoadingHunts || isFetchingHunts) {
      return;
    }

    if (huntsData.hunts.length === 0 && page > 1) {
      setHunts(null);
      navigate(appRoutes.huntsPage(page - 1));
    }

    setHunts(huntsData.hunts);
    setTotalCount(huntsData.totalCount);
    setTotalPages(Math.ceil(huntsData.totalCount / HUNTS_PER_PAGE));
  }, [huntsData, isLoadingHunts, isFetchingHunts, page, navigate]);

  return (
    <AppLayout>
      <AppHead title={t('app:hunts.hunts')} />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.firstRow}>
            <h1>{t('app:hunts.hunts')}</h1>

            <Button
              type="primary"
              onClick={openCreateHuntModal}
              data-testid={HuntTestIds.createButton}
            >
              {t('app:hunts.newHunt')}
            </Button>

            {tier === Tier.FREE && <UpgradeButton className={styles.upgradeButton} />}
          </div>

          <div className={styles.selectOrder}>
            <span>{t('app:hunts.orderBy')}</span>
            <Select
              defaultValue={HuntsFilter.RECENT}
              onChange={handleFilterChange}
              disabled={!!!hunts || totalCount < 1}
            >
              <Select.Option value={HuntsFilter.RECENT}>{t('app:hunts.mostRecent')}</Select.Option>
              <Select.Option value={HuntsFilter.OLDEST}>{t('app:hunts.oldest')}</Select.Option>
            </Select>
          </div>
        </div>

        <div className={styles.hunts}>
          {!(isLoadingHunts || isFetchingHunts) &&
            hunts &&
            hunts.map((hunt) => (
              <div key={hunt.id}>
                <Card
                  loading={isLoadingHunts}
                  cover={
                    <CardCover
                      pictureUrl={hunt.coverPictureUrl || HuntPlaceholder}
                      huntId={hunt.id}
                      loading={isLoadingHunts}
                    />
                  }
                >
                  <Card.Meta
                    className={styles.cardMeta}
                    title={
                      <CardHeader
                        title={hunt.title}
                        date={getLocalizedDateString(hunt.date)}
                        huntId={hunt.id}
                        currentPage={page}
                        filter={filter}
                        openEditHuntModal={openUpdateHuntModal}
                      />
                    }
                    description={hunt.description || ''}
                  />
                </Card>
              </div>
            ))}

          {(isLoadingHunts || isFetchingHunts) && [
            <Card key={0} loading={true} cover={<CardCover loading={true} />} />,
            <Card key={1} loading={true} cover={<CardCover loading={true} />} />,
            <Card key={2} loading={true} cover={<CardCover loading={true} />} />,
          ]}
        </div>

        {hunts && hunts.length === 0 && (
          <div className={styles.noHunts}>
            <HuntIllustration />
            <h2>{t('app:hunts.addFirstHunt')}</h2>
            <p>{t('app:hunts.addNewHunt')}</p>
          </div>
        )}

        {totalPages > 1 && (
          <div className={styles.pagination}>
            <Pagination
              current={page}
              defaultCurrent={page}
              total={totalPages}
              pageSize={1}
              onChange={handlePageChange}
              disabled={isLoadingHunts}
            />
          </div>
        )}
      </div>

      <HuntCreateModal open={showCreateHuntModal} onClose={closeCreateHuntModal} />

      <HuntUpdateModal open={showUpdateHuntModal} onClose={closeUpdateHuntModal} huntId={huntId} />
    </AppLayout>
  );
};

export default HuntsContainer;
