import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { loadSettings, updateSettings } from 'store/settings/settings.thunk';
import TreasuresView from 'shared/constants/treasures-view';

type SettingsState = {
  language: string;
  siderStatus: undefined | 'open' | 'collapsed';
  treasuresView: TreasuresView;
};

const initialState: SettingsState = {
  language: 'en',
  siderStatus: undefined,
  treasuresView: TreasuresView.CARD,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSettings.fulfilled, (state, action) => {
      const newSettings = action.payload;

      newSettings.language != null && (state.language = newSettings.language);
      newSettings.siderStatus != null && (state.siderStatus = newSettings.siderStatus);
      newSettings.treasuresView != null && (state.treasuresView = newSettings.treasuresView);
    });

    builder.addCase(updateSettings.fulfilled, (state, action) => {
      const newSettings = action.payload;

      newSettings.language != null && (state.language = newSettings.language);
      newSettings.siderStatus != null && (state.siderStatus = newSettings.siderStatus);
      newSettings.treasuresView != null && (state.treasuresView = newSettings.treasuresView);
    });
  },
});

export const selectLanguage = (state: RootState) => state.settings.language;

export const selectSiderStatus = (state: RootState) => state.settings.siderStatus;

export const selectTreasuresView = (state: RootState) => state.settings.treasuresView;

export default settingsSlice.reducer;
