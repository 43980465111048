import { PropsWithChildren } from 'react';
import { Loading } from 'shared/components';
import { useUser } from 'shared/hooks';
import { appRoutes } from 'shared/routes/app-routes';

const LoggedUserRedirect = ({ children }: PropsWithChildren<{}>) => {
  const user = useUser({ redirectTo: appRoutes.home(), redirectIfFound: true });

  return !user || user.isLoggedIn ? <Loading /> : <>{children}</>;
};

export default LoggedUserRedirect;
