import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { HomeOutlined, LoginOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { LoginForm, HamburgerMenu } from 'shared/components';
import { useLookupQuery } from 'services/auth';
import { publicRoutes } from 'shared/routes/public-routes';
import { appRoutes } from 'shared/routes/app-routes';
import styles from './index.module.sass';

const PublicMenu = () => {
  const { t } = useTranslation(['translation', 'home', 'auth']);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, isFetching, data } = useLookupQuery({});

  const mobileMenuItems = useMemo(
    () => [
      {
        label: t('home:home'),
        key: publicRoutes.home(),
        icon: <HomeOutlined />,
      },
      {
        label: t('auth:login'),
        key: publicRoutes.login(),
        icon: <LoginOutlined />,
      },
      {
        label: t('auth:signup'),
        key: publicRoutes.signup(),
        icon: <PlusCircleOutlined />,
      },
    ],
    [t]
  );

  const handleLoginClick = useCallback(() => {
    if (isLoading || isFetching) {
      return;
    }

    if (data && data.status === 200) {
      navigate(appRoutes.home());
      return;
    }

    setIsModalVisible(true);
  }, [isLoading, isFetching, data, navigate]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <div className={styles.mobileMenu}>
        <HamburgerMenu items={mobileMenuItems} />
      </div>
      <div className={styles.menu}>
        <button className={styles.loginButton} onClick={handleLoginClick}>
          {t('auth:login')}
        </button>
        <Button type="primary">
          <Link to={publicRoutes.signup()}>{t('auth:signup')}</Link>
        </Button>
      </div>
      <Modal
        title={t('auth:login')}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={400}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button form="loginForm" key="submit" htmlType="submit" type="primary">
            {t('auth:login')}
          </Button>,
        ]}
      >
        <LoginForm showSubmitButton={false} />
      </Modal>
    </>
  );
};

export default PublicMenu;
