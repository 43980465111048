export const getLocalizedDateString = (isoDate?: string | number) => {
  if (!isoDate) {
    return '';
  }

  return new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(isoDate));
};

export const getLocalizedDateTimeString = (isoDate?: string | number) => {
  if (!isoDate) {
    return '';
  }

  return new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'short' }).format(
    new Date(isoDate)
  );
};
