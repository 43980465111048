import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, set } from 'idb-keyval';
import i18n from 'shared/helpers/i18n';
import TreasuresView from 'shared/constants/treasures-view';

enum SettingsKeys {
  LANGUAGE = 'userSettingLanguage',
  SIDER_STATUS = 'userSettingSiderStatus',
  TREASURES_VIEW = 'userSettingTreasuresView',
}

type UpdateSettingsParams = {
  language?: string;
  siderStatus?: 'collapsed' | 'open';
  treasuresView?: TreasuresView;
};

export const loadSettings = createAsyncThunk('settings/loadSettings', async () => {
  let language: undefined | string;
  let siderStatus: undefined | 'collapsed' | 'open';
  let treasuresView: undefined | TreasuresView;

  try {
    language = await get(SettingsKeys.LANGUAGE);
    siderStatus = await get(SettingsKeys.SIDER_STATUS);
    treasuresView = await get(SettingsKeys.TREASURES_VIEW);

    if (!language) {
      language = i18n.resolvedLanguage;
      set(SettingsKeys.LANGUAGE, i18n.resolvedLanguage);
    }

    if (!siderStatus) {
      siderStatus = 'open';
      set(SettingsKeys.SIDER_STATUS, 'open');
    }

    if (!treasuresView) {
      treasuresView = TreasuresView.CARD;
      set(SettingsKeys.TREASURES_VIEW, TreasuresView.CARD);
    }
  } catch (err) {
    console.error(err);

    // On Firefox incognito you can't use idb
    if (!language) {
      language = i18n.resolvedLanguage;
    }

    if (!siderStatus) {
      siderStatus = 'open';
    }

    if (!treasuresView) {
      treasuresView = TreasuresView.CARD;
    }
  }

  return {
    language,
    siderStatus,
    treasuresView,
  };
});

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async ({ language, siderStatus, treasuresView }: UpdateSettingsParams) => {
    if (language != null) {
      set(SettingsKeys.LANGUAGE, language);
      i18n.changeLanguage(language);
    }

    siderStatus != null && set(SettingsKeys.SIDER_STATUS, siderStatus);

    treasuresView != null && set(SettingsKeys.TREASURES_VIEW, treasuresView);

    return {
      language,
      siderStatus,
      treasuresView,
    };
  }
);
