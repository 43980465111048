import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { HuntsFilter } from 'models/hunt';
import { appRoutes } from 'shared/routes/app-routes';
import { HuntTestIds } from 'shared/constants/test-ids';
import { useDeleteHuntMutation } from 'services/hunts';
import styles from './index.module.sass';

type Props = {
  title: string;
  date: string;
  huntId: string;
  currentPage: number;
  filter: HuntsFilter;
  openEditHuntModal: (huntId: string) => void;
};

const CardHeader: FC<Props> = ({ title, date, huntId, currentPage, filter, openEditHuntModal }) => {
  const { t } = useTranslation(['translation', 'app']);
  const [deleteHunt] = useDeleteHuntMutation();

  const showDeleteConfirm = useCallback(() => {
    Modal.confirm({
      title: t('app:hunts.deleteConfirmationTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('app:hunts.deleteConfirmationContent'),
      okText: t('common.delete'),
      okType: 'danger',
      cancelText: t('common.cancel'),
      width: 450,
      onOk() {
        deleteHunt({
          huntId,
          page: currentPage,
          filter,
        });
      },
    });
  }, [deleteHunt, huntId, currentPage, filter, t]);

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            label: t('common.edit'),
            key: 'edit',
            onClick: () => openEditHuntModal(huntId),
          },
          {
            label: t('common.delete'),
            key: 'delete',
            onClick: showDeleteConfirm,
          },
        ]}
      />
    ),
    [t, huntId, openEditHuntModal, showDeleteConfirm]
  );

  return (
    <div className={styles.cardHeader}>
      <div className={styles.titleRow}>
        <Link to={appRoutes.hunt(huntId)}>{title}</Link>
        <Dropdown overlay={menu} placement="top" trigger={['click']} arrow>
          <div className={styles.iconButton}>
            <FontAwesomeIcon icon={faEllipsisVertical} data-testid={HuntTestIds.actionsButton} />
          </div>
        </Dropdown>
      </div>
      <span className={styles.date}>{date}</span>
    </div>
  );
};

export default CardHeader;
