import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import TreasureCreateForm from 'containers/app/treasures/TreasureCreateForm';

type Props = {
  open: boolean;
  onClose: () => void;
  huntId: string;
};

const TreasureCreateModal: FC<Props> = ({ open, onClose, huntId }) => {
  const { t } = useTranslation(['app']);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      title={t('app:treasures.newTreasure')}
      visible={open}
      onCancel={onClose}
      closable={!isLoading}
      maskClosable={!isLoading}
      getContainer={false}
      destroyOnClose={true}
      footer={false}
    >
      <TreasureCreateForm
        huntId={huntId}
        onClose={onClose}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Modal>
  );
};

export default TreasureCreateModal;
