import { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import Navbar from 'shared/components/Navbar';
import { publicRoutes } from 'shared/routes/public-routes';
import styles from './index.module.sass';

const LandingPageLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Layout className={styles.layout}>
      <Navbar homeRoute={publicRoutes.home()} />
      <Layout.Content>{children}</Layout.Content>
      <Layout.Footer className={styles.footer}>Poucher © {new Date().getFullYear()}</Layout.Footer>
    </Layout>
  );
};

export default LandingPageLayout;
