import { FC } from 'react';
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PublicMenu } from 'shared/components';
import styles from './index.module.sass';
import { PoucherLogo } from 'shared/resources/images';

type Props = {
  homeRoute: string;
};

const Navbar: FC<Props> = ({ homeRoute }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(homeRoute);
  };

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.logo} onClick={handleLogoClick}>
        <PoucherLogo id="test" />
        <span>POUCHER</span>
      </div>

      <div className={styles.menu}>{<PublicMenu />}</div>
    </Layout.Header>
  );
};

export default Navbar;
