export type DefaultResponse<T> = {
  success: boolean;
  data?: T;
};

export const transformDefaultResponse =
  <T>() =>
  (response: DefaultResponse<T>) =>
    response.success ? response.data : null;

export type DefaultError = {
  success: boolean;
  errorCodes: Array<string>;
};
