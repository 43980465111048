import { FC, useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { notification, NotificationType } from 'shared/helpers/notification';
import { AuthTestIds } from 'shared/constants/test-ids';
import { useLoginMutation } from 'services/auth';
import { Auth } from 'models/auth';
import { appRoutes } from 'shared/routes/app-routes';
import styles from './index.module.sass';

const reCaptchaSiteKey =
  // @ts-ignore
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.Cypress
    ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    : process.env.REACT_APP_RE_CAPTCHA_SITE_KEY!;

type Props = {
  showSubmitButton?: boolean;
};

const LoginForm: FC<Props> = ({ showSubmitButton = true }) => {
  const { t } = useTranslation(['auth', 'error']);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [login, { isLoading, reset }] = useLoginMutation();
  const [alerts, setAlerts] = useState<null | Array<string>>(null);
  const reCaptchaRef = useRef<null | ReCAPTCHA>(null);

  const handleSubmit = useCallback(
    async (values: Auth.LoginFormValues) => {
      setAlerts(null);

      if (reCaptchaRef.current === null) {
        return;
      }

      values.captchaToken = await reCaptchaRef.current.executeAsync();

      login(values)
        .unwrap()
        .then((data) => {
          if (data && data.success === true) {
            reset();
            notification(t('auth:loginSuccessful'), NotificationType.SUCCESS);
            navigate(appRoutes.home());
          }
        })
        .catch(({ data }) => {
          reCaptchaRef.current?.reset();

          if (data && data.errorCodes) {
            setAlerts(data.errorCodes.map((code: number) => t(`error:${code}`)));
          } else {
            setAlerts([t('error:unknown')]);
          }
        });
    },
    [login, navigate, reset, t]
  );

  return (
    <>
      <div className={styles.alertsWrapper}>
        {alerts &&
          alerts.map((alert) => <Alert message="" description={alert} key={alert} type="error" />)}
      </div>
      <Form
        id="loginForm"
        size="large"
        name="login"
        onFinish={handleSubmit}
        layout="vertical"
        className={styles.form}
        form={form}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('auth:emailValidationRequired') },
            { type: 'email', message: t('auth:emailValidationValid') },
          ]}
        >
          <Input
            type="email"
            placeholder={t('auth:emailPlaceholder')}
            disabled={isLoading}
            data-testid={AuthTestIds.emailInput}
            maxLength={320}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('auth:passwordValidationRequired') },
            { min: 8, message: t('auth:passwordValidationMinLength') },
            { max: 72, message: t('auth:passwordValidationMaxLength') },
          ]}
        >
          <Input.Password
            placeholder={t('auth:passwordPlaceholder')}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            disabled={isLoading}
            data-testid={AuthTestIds.passwordInput}
            maxLength={72}
          />
        </Form.Item>

        <div className={styles.reCaptcha}>
          <ReCAPTCHA ref={reCaptchaRef} sitekey={reCaptchaSiteKey} size="invisible" />
        </div>

        {showSubmitButton && (
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
                data-testid={AuthTestIds.loginButton}
                disabled={
                  !form.isFieldsTouched(true) ||
                  form.getFieldsError().some(({ errors }) => errors.length)
                }
              >
                {t('auth:login')}
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
    </>
  );
};

export default LoginForm;
